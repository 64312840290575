/* Module: S */
[data-icon] {
  &::before {
    display: inline-block;
    font-family: 'pga-icons' !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

[class^='icon-'],
[class*=' icon-'] {
  &::before {
    display: inline-block;
    font-family: 'pga-icons' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.icon-2x {
  &::before {
    font-size: 2rem;
    margin-right: .65rem;
    vertical-align: -.2rem;
  }

  &.icon-checkmark {
    &::before {
      vertical-align: -.6rem;
    }
  }
}

.icon-pbsupdates {
  &::before {
    content: '\e800';
  }
}

.icon-arrow {
  &::before {
    content: '\e801';
    font-size: 1.25rem;
    vertical-align: -.4rem;
    margin-left: .5rem;
  }
  &--up::before {
    @include sq-transform(rotate(-90deg));
  }
  &--left::before {
    @include sq-transform(rotate(180deg));
    margin-left: 0;
    margin-right: .5rem;
    -webkit-transform-origin: 0.75rem 0.5rem;
    transform-origin: 0.75rem 0.5rem;
  }
}

.icon-bustools {
  &::before {
    content: '\e802';
  }
}

.icon-changepw {
  &::before {
    content: '\e803';
  }
}

.icon-checkmark {
  &::before {
    content: '\e804';
  }
}

.icon-close {
  &::before {
    content: '\e805';
  }
}

.icon-contact-mob {
  &::before {
    content: '\e806';
  }
}

.icon-down-arrow {
  &::before {
    content: '\e807';
  }
}

.icon-events {
  &::before {
    content: '\e808';
  }
}

.icon-faqs {
  &::before {
    content: '\e809';
  }
}

.icon-haveyoursay {
  &::before {
    content: '\e80a';
  }
}

.icon-menu-mob {
  &::before {
    content: '\e80b';
  }
}

.icon-bincollection {
  &::before {
    content: '\e80c';
  }
}

.icon-phone {
  &::before {
    content: '\e80d';
  }
}

.icon-qccp {
  &::before {
    content: '\e80e';
  }
}

.icon-sign-in-mob {
  &::before {
    content: '\e80f';
  }
}

.icon-subscriptions {
  &::before {
    content: '\e810';
  }
}

.icon-syringe {
  &::before {
    content: '\e811';
  }
}

.icon-training {
  &::before {
    content: '\e812';
  }
}

.icon-wagerates {
  &::before {
    content: '\e813';
  }
}

.icon-search {
  &::before {
    content: '\e814';
  }
}

.icon-workplace {
  &::before {
    content: '\e815';
  }
}

.icon-changepw-o {
  &::before {
    content: '\e816';
  }
}

.icon-haveyoursay-o {
  &::before {
    content: '\e817';
  }
}

.icon-username-o {
  &::before {
    content: '\e818';
  }
}

.icon-cross {
  &::before {
    content: '\e819';
  }
}
.icon-money {
  &::before {
    content: '\e81a';
  }
}

body {
  font-family: $font-family-base;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%paragraph-common {
  margin-bottom: 1rem;
}

%heading-common {
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
  margin-bottom: 1rem;
}

%heading-small-child-common {
  font-weight: normal;
  line-height: 1;
  color: $small-text-color;
}

%heading-1to3 {
  margin-top: 2rem;
}

%heading-4to6 {
  margin-top: 1rem;
}

%heading-1to3-small-child {
  font-size: 65%;
}

%heading-4to6-small-child {
  font-size: 75%;
}

%heading-1 {
  font-size: $font-size-h1;
  line-height: $line-height-headline;
}

%heading-2 {
  font-size: $font-size-h2;
  line-height: $line-height-section-title;
}

%heading-3 {
  font-size: $font-size-h3;
  line-height: $line-height-headline;
}

%heading-4 {
  font-size: $font-size-h4;
  line-height: $line-height-headline;
}

%heading-5 {
  font-size: $font-size-h5;
  line-height: $line-height-headline;
}

%heading-6 {
  font-size: $font-size-h6;
  line-height: $line-height-headline;
}

%link-common {
  color: color(light-navy);
  border-bottom: 2px solid transparent;

  &:hover,
  &:focus,
  &:active {
    @include sq-box-shadow(0 2px color(light-navy));
    cursor: pointer;
    text-decoration: none;
  }

  &.external {

    &::after {
      content: '\f08e';
      font-family: 'FontAwesome';
      margin-left: 6px;
    }
  }

  &[class^='file-type--'] {
    font-weight: bold;

    &::before {
      content: '\f016';
      font-family: 'FontAwesome';
      font-size: 1.5em;
      margin-right: 10px;
      font-weight: normal;
    }
  }

  &.see-all {
    display: inline-block;
    font-size: pxToRem(14);
    color: #000;
    position: relative;
    // &:hover{
    //   text-decoration: none;
    //   border-bottom: 2px solid color(light-navy);
    //   &::after{
    //     border-bottom: none;
    //   }
    // }
    &::after {
      content: '\e801';
      font-family: 'pga-icons';
      display: inline-block;
      border-bottom: 0;
      position: absolute;
      left: 100%;
      padding-left: pxToRem(30);
    }
  }
}

.content-container {
  color: $body-text-color;
  line-height: $body-text-line-height;

  p {
    @extend %paragraph-common;
  }

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    @extend %heading-common;

    small,
    .small {
      @extend %heading-small-child-common;
    }
  }

  h1,
  .h1 {
    @extend %heading-1;
  }

  h2,
  .h2 {
    @extend %heading-2;
  }

  h3,
  .h3 {
    @extend %heading-3;
  }

  h1,
  h2,
  h3,
  .h1,
  .h2,
  .h3 {
    @extend %heading-1to3;

    small,
    .small {
      @extend %heading-1to3-small-child;
    }
  }

  h4,
  h5,
  h6,
  .h5,
  .h4,
  .h6 {
    @extend %heading-4to6;

    small,
    .small {
      @extend %heading-4to6-small-child;
    }
  }

  .headline {
    &::after {
      content: '';
      display: block;
      width: 50px;
      height: 2px;
      background-color: color(ocean);
      margin: ($headings-line-height * $font-size-base) 0 $font-size-h1;
    }
  }


  a {
    @extend %link-common;
  }

  .file-name {
    color: color(black-two);
    font-weight: 500;
  }

  .file-size {
    font-size: .9em;
  }

  .compact {
    margin-bottom: 0;
  }

}
.main:not(.content-container) {
  .p {
    @extend %paragraph-common;
    line-height: $body-text-line-height;
  }
  .h1, .h2, .h3, .h4, .h5, .h6 {
    @extend %heading-common;
  }
  .h1 {
    @extend %heading-1;
  }
  .h2 {
    @extend %heading-2;
  }
  .h3 {
    @extend %heading-3;
  }
  .h1,
  .h2,
  .h3 {
    @extend %heading-1to3;
    .small {
      @extend %heading-1to3-small-child;
    }
  }
  .h5,
  .h4,
  .h6 {
    @extend %heading-4to6;
    .small {
      @extend %heading-4to6-small-child;
    }
  }
  .link {
    @extend %link-common;
  }
  .compact {
    margin-bottom: 0;
  }
}

blockquote {
  font-size: $font-size-base;
  margin: 1.625rem 0;
  padding: 0 1rem;
  border-left: 5px solid color(ocean);

  &::before {
    content: '';
  }
}
