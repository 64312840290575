/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
//
// .footer-nav__element { ... }
// .footer-nav__element--modifier { ... }
// .footer-nav__sub-element { ... }

.footer-nav {
  padding-top: 40px;

  .row {
    margin: 0 auto;
    width: 1080px;
    display: flex;
    justify-content: flex-start;
    padding-top: 20px;
    padding-bottom: 60px;

    .col {
      flex: 1;
      margin-right: 14px;

      &:last-child {
        margin-right: 0;
      }

      h4 {
        margin-bottom: 20px;
      }

      .footer-nav__title-link {
        font-size: 16px;
        line-height: 20px;
      }

      a {
        color: #fff;
      }

      ul {
        display: block;
        padding-left: 0;
        list-style: none;

        li {
          min-height: 25px;
          height: auto;
          line-height: 25px;
          margin-bottom: 5px;

          &.separator {
            height: 15px;
            line-height: 15px;
            margin-bottom: 0;

            .line {
              margin-top: 14px;
              width: 30px;
            }
          }
        }
      }
    }
  }

  .copyright {
    width: 100%;
    text-align: center;

    span {
      display: inline-block;
    }
  }
}
