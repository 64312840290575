/* Module: Accordion */
.accordion__item {
  .accordion__link {
    padding: 20px 30px;
    font-size: 18px;
    font-weight: bold;
  }
}


.js-enabled {
  .accordion__target {
    padding-left: 30px;
    font-size: 16px;
    line-height: 1.6;

    &.uber-accordion__target-active {
      // Height needs to be set to something far beyond whatever the content will be
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
}


.tabs__link {
  &.mobile {
    display: none;
  }
}

.tabs__tab-list {
  &.wide {
    display: flex;
    border-bottom: 1px solid color(light-navy);

    .tabs__item {
      flex: 1;
      text-align: center;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }

      .tabs__link {
        font-size: 18px;
        font-weight: bold;

        &::after {
          content: '';
        }

        &.uber-accordion__button-active {
          background-color: color(light-navy);
          color: #fff;
        }
      }
    }
  }
}


.js-enabled .tabs__target {
    padding-left: 0;
    font-size: 16px;

    &.uber-accordion__target-active {
      padding-top: 15px;
        padding-bottom: 0;
    }
}