/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass
// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.
// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of 
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
// 
// .contact__element { ... }
// .contact__element--modifier { ... }
// .contact__sub-element { ... }
.contact {
  &__section-title-row {
    .title {
      display: inline-block;
      text-align: left;
      width: 50%;
    }

    a {
      &.more-link {
        float: right;
        width: auto;
        text-align: right;
      }
    }
  }

  &__info-block {
    text-align: left;
  }

  &__info-block-title {
    font-size: 20px;

    .contact-icon {
      width: 20px;
      height: 20px;
    }
  }

  &__details-row {
    display: flex;
  }

  &__details-primary {
    flex-basis: 50%;
  }

  &__details-communication {
    flex-basis: 25%;
    padding-left: 10px;
  }

  &__details-web {
    flex-basis: 25%;
    padding-left: 10px;
  }

}
