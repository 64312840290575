/* Module: S */
.pagination-results {

    display: flex;
    flex-flow: row nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;

    &__count,
    &__pages {
        width: 50%;
        display: flex;
        flex-flow: row wrap;
        align-content: center;
        align-items: center;
    }

    &__count {
        justify-content: flex-start;
        margin: 0;
    }

    &__pages {
        justify-content: flex-end;
        align-content: center;
        align-items: center;
    }

}