/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
//
// .footer-nav__element { ... }
// .footer-nav__element--modifier { ... }
// .footer-nav__sub-element { ... }

.footer-nav {

  .row {
    width: 532px;

    h4 {
      margin-bottom: 28px;

      a {
        font-size: 24px;
        line-height: 1;
      }
    }
  }

  .copyright {
    width: 532px;
    margin: 0 auto;
    font-size: 14px;
    line-height: 1.9;
    padding-bottom: 54px;
  }
}
