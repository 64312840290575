/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
//
// .hero__element { ... }
// .hero__element--modifier { ... }
// .hero__sub-element { ... }

.hero {
	width: 100%;
	text-align: center;
	position: relative;
	background-color: color(white-two);
	border-bottom: 1px solid color(white-three);
	padding-top: 1px;

	&--bg-white {
		background-color: $white;
	}

	.hero__bg{
	    background-repeat: no-repeat;
	    position: absolute;
	    &.left{
	      bottom: 0;
	      left: 0;
	      width: 330px;
	      height: 480px;
	      background-image: url("{{file_dest}}/hero-left.png");
	      background-position: top left;
	    }

	    &.top-right{
	      top: 0;
	      right: 0;
	      width: 0;
	      height: 0;
	   //    background-image: url("{{file_dest}}/hero-top-right.png");
		  // background-position: 15px 10px;
		  // background-size: 350px;
	    }

	    &.bottom-right{
	      bottom: 0;
	      right: 0;
	      width: 440px;
	      height: 180px;
	      background-image: url("{{file_dest}}/hero-bottom-right.png");
	      background-position: bottom left;
	    }
	}

 	&__content {
	    position: relative;
 		z-index: 9;
		h1{
			font-size: 24px;
			padding : 0 40px;
			margin-bottom: 30px;
			margin-top: 45px;
		}
		p{
			margin-top: 30px;
			color: color(greyish-brown-three);
		}
		.benefits{
          display: inline-block;
	      font-size: 16px;
	      font-weight: 500;
	      margin-top: 30px;
	      margin-bottom: 30px;
          text-align: left;
	      .benefits__item{
	        @include sq-flex-box();
	        margin-top: 10px;
            a {
              color: black;
            }
	      }
	    }
	    .action{
	    	margin-bottom: 35px;
	    }

 	}

	&.regional{
	    background-position: center center;
	    background-size: cover;
	    height: 100%;
		.white-gradient{
	        position: absolute;
	        width: 100%;
	        height: 100%;
	        top: 0;
	        left: 0;
		    background:rgba(255,255,255, 0.6);
		}
		.hero__content{
			p{
				margin-bottom: 40px;
	        }
		}
	}
}
