/* Module: Off canvas nav */
.off-canvas__nav {
  // Turned off transitions because we don't have an Ajax login
  @include sq-transition(initial);
  overflow: hidden;
  position: absolute;
  visibility: visible;
  width: $off-canvas__nav-width--wide;
  &.off-canvas__nav--right {
    @include sq-transform(translateX(0%));
    right: 0;
    & ~ .off-canvas__content {
      left: 0;
      width: 100% - $off-canvas__nav-width--wide;
    }
  }
}

.off-canvas__content {
  // Turned off transitions because we don't have an Ajax login
  @include sq-transition(initial);
  overflow-y: hidden;
  position: relative;
  width: 100%;
}
