/* Module: S */
.filter-bar {

    margin: 0 0 pxToRem(32) 0;

    &__current-selected {
        display: none;
    }

    &__items {
        display: flex !important;
        justify-content: flex-start;
        align-content: center;
        align-items: center;
        flex-flow: row wrap;

        li {

            border: none;
            margin: 0 pxToRem(10) pxToRem(10) 0;
            padding: 0;

            &.disabled {
                @include sq-border-radius(999px);
                padding: pxToRem(7) pxToRem(17);
            }

            a {
                @include sq-border-radius(999px);
                width: auto;
                padding: pxToRem(7) pxToRem(17);
            }
        }
    }

}