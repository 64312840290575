/* Module: S */
.member-nav {
  &__logo {
    align-items: center;
    margin: 1.25rem 0;
    text-align: center;
    @include sq-font-size(2);
    @include sq-flex-box();
    img {
      margin-right: 0.875rem;
      max-width: 60px;
      padding-right: 0.875rem;
    }
  }
  &__sign-out.button {
    min-width: 8rem;
    padding: 1rem 1.625rem;
    @include sq-font-size(0.875);
  }
}
