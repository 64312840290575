// Use the same CSS naming conventions for your variables, this will make it easier to manage
// them in a modular way and make sure that you aren't creating something that conflicts.
// We use !default so you can override these variables by including them higher up in the flow
// of the sass pre-processor (e.g the core variables.scss file).

// Examples:
// $utils__background: #000 !default;
// $utils__text: #fff !default;

/// Replace `$search` with `$replace` in `$string`
/// @author Hugo Giraudel
/// @param {String} $string - Initial string
/// @param {String} $search - Substring to replace
/// @param {String} $replace ('') - New value
/// @return {String} - Updated string
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

@mixin transform-origin($value) {
  -webkit-transform-origin: $value;
  -moz-transform-origin: $value;
  -ms-transform-origin: $value;
  -o-transform-origin: $value;
  transform-origin: $value;
}

@mixin writing-mode($value) {
  writing-mode: $value;
  -webkit-writing-mode: $value;
  -ms-writing-mode: str-replace(str-replace($value, 'horizontal', 'lr'), 'vertical', 'tb');
}

@mixin mq-tablet() {
    @media screen and (min-width: 1000px) {
        @content;
    }
}
