/* Module: S */
// Rounded borders
.round-left {
  @include sq-rounded(top, left, $default-radius);
  @include sq-rounded(bottom, left, $default-radius);
}
.round-right {
  @include sq-rounded(top, right, $default-radius);
  @include sq-rounded(bottom, right, $default-radius);
}
.round-all {
  @include sq-border-radius($default-radius);
}