@font-face {
  font-family: 'Cabin';
  font-weight: 400;
  font-style: normal;
  src: url('{{file_dest}}/Cabin-regular.eot'),
  url('{{file_dest}}/Cabin-regular.eot?#iefix') format('embedded-opentype'),
  local('Cabin Regular'),
  local('Cabin-regular'),
  url('{{file_dest}}/Cabin-regular.woff2') format('woff2'),
  url('{{file_dest}}/Cabin-regular.woff') format('woff'),
  url('{{file_dest}}/Cabin-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Cabin';
  font-weight: 500;
  font-style: normal;
  src: url('{{file_dest}}/Cabin-500.eot'),
  url('{{file_dest}}/Cabin-500.eot?#iefix') format('embedded-opentype'),
  local('Cabin Medium'),
  local('Cabin-500'),
  url('{{file_dest}}/Cabin-500.woff2') format('woff2'),
  url('{{file_dest}}/Cabin-500.woff') format('woff'),
  url('{{file_dest}}/Cabin-500.ttf') format('truetype');
}

@font-face {
  font-family: 'pga-icons';
  src: url('{{file_dest}}/pga-icons.eot?39333314');
  src: url('{{file_dest}}/pga-icons.eot?39333314#iefix') format('embedded-opentype'),
       url('{{file_dest}}/pga-icons.woff2?39333314') format('woff2'),
       url('{{file_dest}}/pga-icons.woff?39333314') format('woff'),
       url('{{file_dest}}/pga-icons.ttf?39333314') format('truetype'),
       url('{{file_dest}}/pga-icons.svg?39333314#pga-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

$font-family-base: Cabin, "Helvetica Neue", Helvetica, Arial, sans-serif;

$font-size-base: 16px;
$font-size-large: $font-size-base * 1.125; // ~18px
$font-size-small: $font-size-base * 0.875; // ~14px

$font-size-h1: $font-size-base * 2.625; // ~42px
$font-size-h2: $font-size-base * 2.25; // ~36px
$font-size-h3: $font-size-base * 1.5; // ~24px
$font-size-h4: $font-size-base * 1.125; // ~18px
$font-size-h5: $font-size-base;
$font-size-h6: $font-size-base * 0.875; // ~14px

$headings-font-family: inherit;
$headings-font-weight: 700;
$headings-line-height: 1.875;
$headings-color: black;

$body-text-color: color(greyish-brown-three);
$body-text-line-height: 1.625;

$line-height-headline: 1.1;
$line-height-section-title: $font-size-h1;

$small-text-color: color(black-two);
