/* Module: S */
.contact {

  &__section {
    margin: 20px auto 100px;
  }

  &__section-title-row {
    border-bottom: 1px solid color('pinkish-grey');
    padding-bottom: 20px;

    .title {
      font-size: 28px;
      line-height: 48px;
      font-weight: bold;
      display: block;
      color: #000;
      text-align: center;
    }

    a {
      &.more-link {
        font-size: 16px;
        color: #000;
        padding-top: 12px;
        text-align: center;
        display: block;
      }
    }

  }

  p {
    &.compact {
      margin: 0;
      line-height: 1.5;
    }
  }

  &__info-block {
    // text-align: center;
    margin-top: 20px;
  }

  &__info-block-title {
    font-size: 24px;
    line-height: 32px;
    color: #000;
    font-weight: bold;
    margin-top: 20px;

    .contact-icon {
      display: inline-block;
      width: 32px;
      height: 32px;
      margin-right: 5px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      vertical-align: middle;
      margin-top: -2px;

      &.phone {
        background-image: url('{{file_dest}}/contact-phone.svg');
      }

      &.email {
        background-image: url('{{file_dest}}/contact-email.svg');
      }

      &.web {
        background-image: url('{{file_dest}}/contact-web.svg');
      }
    }
  }

}
