/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
//
// .latest-news__element { ... }
// .latest-news__element--modifier { ... }
// .latest-news__sub-element { ... }

.latest-news-cct {

	clear: both;
	width: 100%;

	&__inner {
		padding: 0 pxToRem(40) 0 pxToRem(40);
	}

	&__lower {
		display: flex;
		width: 100%;
		justify-content: space-between;
		flex-flow: row nowrap;
	}
	
	&__title {
		
		margin: 0 0 pxToRem(58) 0;

		&-text {
			margin-bottom: pxToRem(20);
		}
	}

	.news-item {
		width: 25%;
		margin: 0;

		h4{
			margin-bottom: pxToRem(20);
			padding: 0 0 pxToRem(20) 0;
		}

		.date{
			margin-top: 20px;
			font-size: 14px;
			line-height: 1.6;
			color: color(greyish-brown-two);
		}

		.description { 
			display: block;
			margin-top: 30px;
			.more{
				padding-left: 10px;
				padding-right: 10px;
				text-decoration: none;
				font-size: 20px;
				vertical-align: text-top;
				color: color(ocean);
				&:hover{
					text-decoration: underline;
				}
			}
		}

	}

	.action {
		width: 100%;
		clear: both;
		margin-top: pxToRem(45);
		text-align: center;

		.button{
			font-size: 14px;
		}
	}

}

.latest-news-cct {
  padding-top: 68px;
}

.off-canvas--active + .off-canvas__content .latest-news-cct{
    background-image:none;
}
