/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of 
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
// 
// .sign-in__element { ... }
// .sign-in__element--modifier { ... }
// .sign-in__sub-element { ... }

.sign-in {
  &__content {
    padding: 4.5rem;
    width: 50%;
    max-width: 785px;
    &:before {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }
  }
  &__heading {
    margin: 0 0 4.5rem;    
  }
  &__close {
    top: 1.5rem;
    right: 1.5rem;
  }
}