/* Module: S */
.content-container {
  .headline {
    &::after {
      display: none;
    }
  }
  a {
    &[class^='file-type--'] {
      font-weight: normal;
      &::before {
        content: '';
        display: none;
      }
      &::after {
        content: " (file: " attr(href) ")";        
      }
    }
  }
}