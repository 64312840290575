/* Module: S */
.row-advert {

    width: 100%;
    background-color: $very-light-pink;
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    padding: pxToRem(35) 0;

    &__inner {
        width: 100%;
        max-width: pxToRem($max-width);
    }

    &__title {
        font-size: pxToRem(16);
        line-height: pxToRem(27);
        text-align: center;
    }

    &__content {
        text-align: center;

        img {
            max-width: 100%;
        }
    }


}