/* Module: S */
.input-group {
  width: auto;
}

.input-wrapper {
  input {
    width: auto;
  }
}

.content-container {
  select {
    width: pxToRem(350);
  }

  input {
    max-width: pxToRem(352);
    width: auto;

    &[type='file'] {
      max-width: pxToRem(350);
    }
  }
}

.inline-field {
  @include sq-flex-box;
  align-items: center;

  label {
    @include sq-flex(30%);
    line-height: 54px;
    max-width: 250px;
  }

  .input-control {
    @include sq-flex(70%);
    max-width: none;
    width: calc(100% - 250px);
  }
}
