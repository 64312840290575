/* Module: S */
.content-container .block-icons,
.block-icons {

    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    align-items: stretch;

    &__main-title {
        width: 100%;
    }

    &__item {
        width: 50%;
        height: auto;
        max-height: 100%;
        overflow: hidden;
        position: relative;
        
        &-title,
        &-content {
            width: 100%;
            max-width: pxToRem(280);
          
        }

        &-title {
            font-size: pxToRem(24);
            line-height: pxToRem(30);
            font-weight: 600;
            margin: 0 0 pxToRem(5) 0;
        }

        &-content {
            padding-right: pxToRem(45);
            font-size: pxToRem(16);
            line-height: pxToRem(26);
            // margin: 0 0 pxToRem(29) 0;
            padding: 0 0 pxToRem(80) 0;
        }
    }

    &__buttons {
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;

        a {
            padding: pxToRem(16) pxToRem(17);
        }
    }

}