/* Module: S */
// Use on figure, or use Matrix 'Image' content type
.image-caption,
figure:not([class]) {
  display: table;
  margin-bottom: 2.5rem;
  width: 100%;
  text-align: center;
  // .image-caption__image-wrapper {
  //   // image wrapper styles
  // }
  // .image-caption__image {
  //   // inline image styles
  // }
  .image-caption__caption,
  figcaption:not([class]) {
    caption-side: bottom;
    display: table-caption;
    font-style: italic;
   
  }
  p + & {
    margin-top: 2.5rem;
  }
}
