/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of 
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
// 
// .footer-social__element { ... }
// .footer-social__element--modifier { ... }
// .footer-social__sub-element { ... }

.footer-social {
  width: 100%;

  .footer__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding-top: 25px;
  }

  .footer-social__icons {
    order: 2;
    width: 266px;
    margin: 23px auto 0;

    a {
      font-size: 24px;
      line-height: 1.6;
      color: #fff;
      min-width: 40px;
      display: inline-block;
      text-align: center;
      margin-right: 10px;

      &:hover {
        color: color(white-three);
      }
    }
  }

  .footer-social__subscribe {
    order: 1;
    padding-left: 10px;
    padding-right: 10px;

    p {
      width: 100%;
      margin: 0 auto;
      font-size: pxToRem(12);
      line-height: 1.4;
    }
    label {
      padding-left: 0.875rem;
      margin-bottom: pxToRem(10);
    }
    .input-wrapper {
      padding: 0.5rem 1rem;
      font-size: 16px;
      background: none;
      border: 1px solid $white;

      input {
        width: 100%;
        color: $white;
        @include placeholder() {
          color: $white;
        }
      }

    }
    .button {
      font-size: pxToRem(12);
    }
  }
}
