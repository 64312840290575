/* Module: Accordion */
.js-enabled .accordion__link {
  padding: 0;
  font-weight: bold;
  text-decoration: none;
  &:after {
    content: '';
    display: none;
  }
}

.js-enabled .accordion__target {
  // Height needs to be set to something far beyond whatever the content will be
  max-height: 100em;
  padding-top: $base-accordion-target-spacing;
  padding-bottom: $base-accordion-target-spacing;
}

.js-enabled .tabs__target,
.no-js .tabs__target {
  display: block;
  padding-top: $base-accordion-target-spacing;
  padding-bottom: $base-accordion-target-spacing;
}

.tabs__item .tabs__link {
  padding: 0;
  font-weight: bold;
  text-decoration: none;
  &:after {
    content: '';
    display: none;
  }
}