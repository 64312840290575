/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
//
// .mobile-header__element { ... }
// .mobile-header__element--modifier { ... }
// .mobile-header__sub-element { ... }

.mobile-header {
  position: relative;
  left: 0;
  background-color: white;
  border-bottom: 1rem solid color(ocean);
  justify-content: space-between;
  align-items: stretch;
  @include sq-flex-box();
  @include sq-transition(all 0.5s);
  &__logo {
    order: 0;
    align-self: stretch;
    img {
      width: 100%;
      height: auto;
      max-width: 50px;
      max-height: 50px;
    }
  }
  &__menu,
  &__phone {
    order: -1;
  }
  &__search,
  &__sign-in {
    order: 1;
  }
  > a {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: color(light-navy);
    padding: .5rem;
    text-decoration: none;
    text-transform: uppercase;
    @include sq-flex(1 1 20%);
    @include sq-flex-box();
    font-size: .75rem;
    > i {
      font-size: 1.625rem;
      .no-js &,
      .no-flexbox & {
        display: block;
      }
    }
    &.active,
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      background-color: color(light-navy);
      color: white;
    }
    .no-js &,
    .no-flexbox & {
      display: table-cell;
    }
  }
  &__menu {
    &.active {
      margin-left: -1.5%;
    }
  }
  &__search {
    .no-js &,
    .no-flexbox & {
      display: none !important;
    }
  }
  &__sign-in {
    &.signed-in {
      background-color: color(light-navy);
      color: white;
      text-transform: none;
    }
    &.active {
      margin-right: -1.5%;
      text-transform: uppercase;
    }
  }
  .active-mobile-menu & {
    left: $off-canvas__nav-width;
  }
  .no-js &,
  .no-flexbox & {
    text-align: center;
    width: 100%;
  }
}

.active-mobile-menu .off-canvas__content {
  left: $off-canvas__nav-width;
}
@include mq-tablet() {
  .active-mobile-menu .off-canvas__content {
    left: 0;
  }
}

.mobile-search {
  display: none;
  background-color: color(light-navy);
  color: white;
  padding: 1rem;
  @include sq-font-size(0.875);
  &__input-group {
    width: 100%;
  }
  &__input-wrapper {
    background-color: white;
    border-color: white;
    width: 100%;
  }
  &__button.button {
    min-width: 0;
    padding: 0.625rem 1rem;
    @include sq-font-size(1.375);
    i:before {
      vertical-align: -0.2rem;
    }
  }
  input {
    margin-left: 0.5rem;
    width: 100%;
    @include placeholder() {
      color: black;
    }
  }
  .no-js & {
    display: block;
  }
}
.mobile-sign-in {
  display: none;
  background-color: color(light-navy);
  color: white;
  padding: 2rem;
  text-align: center;
  &__input-wrapper {
    background-color: white;
    border-color: transparent;
    color: black;
    margin: 0 auto 0.875rem;
    text-align: left;
    width: pxToRem(215);
    i {
      margin-right: 1.5rem;
      &::before {
        // color: color(butterscotch);
        @include sq-transform(unquote("scale(2) translate(3px, 1px)"));
      }
    }
    input {
      width: 70%;
    }
  }
  &__input {
    @include placeholder() {
      color: black;
    }
  }
  &__link {
    color: white;
  }
}
.mobile-menu {
  background-color: color(greyish-brown-three);
  color: white;
  padding: 1rem 0 3rem;
  text-transform: uppercase;
  @include sq-box-shadow(none);
  &__list {
    @include sq-list-reset();
    li {
      border-bottom: 1px solid color("white-45p");
      margin: 0 1rem;
      padding: 1rem;
      @include sq-font-size(1);
      &:last-child {
        border-bottom: none;
      }
    }
    a {
      color: white;
      display: block;
      text-decoration: none;
      &:hover,
      &:active,
      &:focus {
        text-decoration: underline;
      }
    }
  }
  &__contact {
    margin-top: 2rem;
    text-align: center;
    i {
      margin-right: 0.5rem;
      &:before {
        vertical-align: -0.1rem;
      }
    }
    .no-js &,
    .no-flexbox & {
      margin: 1rem 0;
    }
    .feedback__toggle {
      position: static;
      .no-js & {
        display: none;
      }
    }
  }
  .no-js &,
  .no-flexbox & {
    display: none;
    transform: none;
    visibility: visible;
    width: 100%;
    position: static;
    padding: 0;
    &:target {
      display: block;
    }
  }
}

.mobile-select-state {
  background-color: color(ocean);
  &__list {
    flex-wrap: wrap;
    padding: 0 1rem;
    @include sq-flex-box();
    li {
      flex-basis: 50%;
      margin: 0;
      &:first-child {
        flex-basis: 100%;
      }
      &:nth-child(2n+2) {
        border-right: 1px solid color("white-45p");
      }
      &:nth-last-child(2), &:nth-last-child(1) {
        border-bottom: none;
        margin-bottom: 1rem;
        padding-bottom: 0;
      }
      &.active {
        background-color: color(butterscotch);
        color: color(greyish-brown-three);
        &:nth-child(2n+2) {
          border-right: none;
        }
        &:nth-last-child(2), &:nth-last-child(1) {
          margin-bottom: 0;
          padding-bottom: 1rem;
        }
        a {
          color: color(greyish-brown-three);
        }
      }
      .no-js &,
      .no-flexbox & {
        border-right: none;
      }
      &:nth-last-child(2) {
        .no-js &,
        .no-flexbox & {
          border-bottom: 1px solid color("white-45p");
          margin: 0;
          padding: 1rem;
        }
      }
    }
    .no-js &,
    .no-flexbox & {
      width: 100%;
    }
  }
}

@include mq-tablet() {
  .mobile-header,
  .mobile-search,
  .mobile-sign-in,
  .mobile-menu {
    display: none !important;
  }
}
