$colours: (
  "black": #000000,
  "black-two": #363636,
  "butterscotch": #ffc937,
  "greyish-brown": #434343,
  "greyish-brown-two": #505050,
  "greyish-brown-three": #575757,
  "light-navy": #143980,
  "ocean": #008390,
  "pinkish-grey": #c3c3c3,
  "purple-brown": #231f20,
  "warm-grey": #9b9b9b,
  "white": #ffffff,
  "white-two": #efefef,
  "white-three": #d5d5d5,
  "white-45p": rgba(255, 255, 255, 0.45),
  "scarlet": #d0021b,
  "golden-glow": #fce39a,
  "guild-navy": #13387f,
  "guild-teal": #358490
);

@function color($key) {
  @if not map-has-key($colours, $key) {
    @warn "Key `#{$key}` not found in $colours map.";
  }
  @return map-get($colours, $key);
}