/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
//
// .latest-news__element { ... }
// .latest-news__element--modifier { ... }
// .latest-news__sub-element { ... }

.latest-news-cct {

	&__lower {
		margin: 0 auto;
		width: 65%;
	}

	.action{
		.button{
			font-size: 14px;
		}
	}
}

.latest-news-cct {
  padding-top: 62px;
}
