/* Module: S */
// typeahead.css

.twitter-typeahead {
    width: 100%;
    @include mq-tablet() {
        width: auto;
    }
}

.tt-menu {
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #e6e6e6;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
    color: #333;
    font-size: 0.875rem;
    margin: 1rem 0.5rem;
    padding-bottom: 5px;
    text-align: left;
    width: 75vw;
    .results-list & {
        width: 65vw;
        margin: 0.875rem 0.5rem;
        max-width: 17rem;
    }
    @include mq-tablet() {
        &.tt-menu {
            margin: 0 0 0 1rem;
            width: 100%;
        }
    }
}

.tt-menu .tt-suggestion {
    clear: both;
    color: #333;
    display: block;
    font-weight: 300;
    line-height: 1.42857;
    padding: 3px 10px;
    text-align: left;
}

.tt-menu .tt-cursor,
.tt-menu .tt-suggestion:hover {
    background-color: darken(#fff, 5%);
    text-decoration: underline;

    a {
        text-decoration: none;
    }
}

.tt-menu .tt-category {
    background-color: #f5f5f5;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
    margin: 3px 0;
    padding: 7px 5px;
}

.tt-horizontal .tt-dataset .tt-category,
.tt-menu .tt-dataset:first-child .tt-category {
    border-top: none;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin-top: 0;
}

.tt-menu .tt-group {
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
    padding: 3px 5px;
    text-align: center;
}

.tt-menu .tt-group:first-of-type {
    border-top: none;
}

.tt-horizontal {
    width: 200%;
    width: 50vw;

    .header-search & {
        margin-top: 20px;
    }

    .search-page__content-search & {
        width: 125%;
        width: 50vw;
    }
}

.tt-horizontal .tt-dataset {
    float: left;
    width: 100%;
}

.tt-scrollable {
    overflow-y: auto;
    max-height: 460px;
}

.tt-hint {
    color: #ccc !important;
}

.tt-suggestion {
    cursor: pointer;
    .tt-highlight {
        font-weight: bold;
    }

    a {
        cursor: pointer;
        display: inline-block;
        font-weight: 300;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.tt-no-suggestions {
  padding: 3px 10px;
}
