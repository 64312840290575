/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of 
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
// 
// .latest-news__element { ... }
// .latest-news__element--modifier { ... }
// .latest-news__sub-element { ... }

.latest-news-cct {
	clear: both;
	width: 100%;
	background-color: $very-light-pink;
	display: flex;
	justify-content: center;
	flex-flow: row nowrap;

	&__inner {
		width: 100%;
		max-width: pxToRem($max-width);
		display: flex;
		flex-flow: row wrap;
		padding: pxToRem(19) pxToRem($padding) 0 pxToRem($padding);
	}

	&__title {
		width: 100%;
		display: flex;
		justify-content: center;
		flex-flow: row wrap;
		margin: 0 0 pxToRem(40) 0;
		text-transform: uppercase;

		&:after {
			content: "";
			width: pxToRem(75);
			height: pxToRem(1);
			background-color: $ocean;
			display: inline-block;
		}

		&-text {
			@include font-smoothing;
			font-size: pxToRem(24);
			line-height: pxToRem(27);
			color: $black;
			font-weight: 600;
			position: relative;
			width: 100%;
			text-align: center;
			margin: 0 0 pxToRem(10) 0;
		}
	}

	.news-item {

		margin: 0 0 pxToRem(35) 0;

		&__type,
		&__type a {
			color: $light-navy;
			text-decoration: none;
		}

		&__type {
			font-size: pxToRem(14);
			line-height: pxToRem(23);
			font-weight: normal;
			margin: 0 0 pxToRem(12) 0;
		}

		&__member-only {

			margin: 0 0 pxToRem(40) 0;

			span {
				@include font-smoothing;
				display: inline-block;
				padding: pxToRem(6) pxToRem(9);
				width: auto;
				background-color: $light-navy;
				color: $white;
				font-size: pxToRem(13);
				line-height: pxToRem(13);
				border-left: pxToRem(4) solid $butterscotch;
				text-transform: uppercase;
			}
		}

		h4 {
			font-size: pxToRem(20);
			line-height: pxToRem(23);
			font-weight: 600;
			padding: 0 0 pxToRem(15) 0;
			margin: 0 0 pxToRem(10) 0;
			position: relative;

			&:after {
				content: "";
				width: pxToRem(50);
				height: pxToRem(1);
				background-color: $ocean;
				left: 0;
				bottom: 0;
				position: absolute;
			}

			a {
				color: color(greyish-brown-two);
			}
		}

		.date {
			font-size: pxToRem(14);
			line-height: pxToRem(23);
			color: $greyish-brown-four;
			text-transform: uppercase;
			font-weight: normal;
			margin: 0 0 pxToRem(9) 0;
		}

		.description {

			color: $greyish-brown-four;
			font-size: pxToRem(16);
			line-height: pxToRem(23);
			font-weight: normal;
			margin: 0 0 pxToRem(27) 0;
		}

		&__cta {
			a {
				color: $ocean;
				@include sq-transition(padding 0.4s ease);

				&:hover,
				&:focus {
					padding-left: pxToRem(15);
				}
			}

			.icon-arrow {
				&:before {
					margin: 0;
				}
			}
		}

	}
	
	.action{
		clear: both;
		margin-top: 30px;
		text-align: center;
		width: 100%;
	}

}

.latest-news-cct {
  padding-top: 31px;
  padding-bottom: 50px;
}

.off-canvas--active + .off-canvas__content .latest-news-cct{
    background-image:none;
}