/* Module: S */
.event-blocks {

    &--sml {
        width: 25%;
    }

    &__header {
        flex-flow: row nowrap;
    }

    &__title {
        max-width: 100%;
    }

}