/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
//
// .icon-tiles__element { ... }
// .icon-tiles__element--modifier { ... }
// .icon-tiles__sub-element { ... }


.icon-tiles {

  &__heading {
    text-transform: uppercase;
  }

  .icon-tiles__item {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0;

    a {
      color: #000;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

    .icon-wrapper {
      flex-basis: 30%;
      text-align: right;
      img {
        max-width: 70px;
        width: auto;
      }
    }

    .text-wrapper {
      flex-basis: 70%;
    }

    h3 {
      font-size: 24px;
      margin-bottom: 12px;
    }

    p {
      font-size: 16px;
    }
  }
}
