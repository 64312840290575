/* Module: S */
// Use on figure, or use Matrix 'Image' content type
.image-caption {
  display: block;
  margin-bottom: 1.5rem;
  // .image-caption__image-wrapper {
  //   // image wrapper styles
  // }
  // .image-caption__image {
  //   // inline image styles
  // }
  .image-caption__caption,
  figcaption {
    display: block;
    font-style: italic;
  }
  p + & {
    margin-top: 1.5rem;
  }
}