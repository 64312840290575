/* Module: S */
.list-tiles {

    &__item {
        padding: pxToRem(60) pxToRem(93) pxToRem(46) pxToRem(93);

        &-tag {
            padding: pxToRem(7) pxToRem(10);
        }

        &-title {
            font-size: pxToRem(24);
            line-height: pxToRem(26);
            margin: pxToRem(30) 0 0 0;

            span {
                padding-bottom: pxToRem(23);
            }
        }

        &-content {
            line-height: pxToRem(26);
            margin: pxToRem(21) 0 0 0;
            max-width: pxToRem(380);
        }

        &-cta {
            margin: pxToRem(40) 0 0 0;
        }
    }

}