/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of 
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
// 
// .footer-social__element { ... }
// .footer-social__element--modifier { ... }
// .footer-social__sub-element { ... }
.footer-social {

  .footer__container {
    display: block;
    width: 1080px;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 25px;
	}

  .footer-social__icons {
    display: inline-block;
    width: 49%;
    vertical-align: top;
    order: 1;
    margin-top: 0;

    a {
      font-size: 30px;
      font-weight: bold;
      line-height: 1.6;
      color: #fff;
      min-width: 40px;
      display: inline-block;
      text-align: center;
      margin-right: 10px;

      &:hover {
        color: color(white-three);
      }

      &:first-child {
        margin-left: 0;
        min-width: 0;
        margin-right: 20px;
      }
		}
	}

  .footer-social__subscribe {
    display: inline-block;
    width: auto;
    order: 2;
    float: right;

    p {
      font-size: 14px;
      line-height: 1.6;
    }

    label {
      padding-left: 0.875rem;
      margin-bottom: 10px;
    }

    .input-wrapper {
      font-size: 14px;

      input {
        width: 330px;
        font-weight: 300;
			}
		}

    .button {
      font-size: 14px;
		}
	}
}
