// Use the same CSS naming conventions for your variables, this will make it easier to manage
// them in a modular way and make sure that you aren't creating something that conflicts.
// We use !default so you can override these variables by including them higher up in the flow
// of the sass pre-processor (e.g the core variables.scss file).

// Examples:
// $select-state__background: #000 !default;
// $select-state__text: #fff !default;
// 

 $select-state-height: 34px;