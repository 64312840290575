/* Module: S */
.date-wrapper {
  position: relative;
}
.loading-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: #fff;
  text-align: center;
  color: #000;
  font-size: 24px;
  top: 0;
  left: 0;
  padding-top: 200px;
  opacity: .95;
  display: none;
}

.trading-hours-container {
  .row {
    @include sq-flex-box();
    justify-content: space-between;
    flex-direction: column;

    .group {
      margin: 10px;
    }
  }
}
