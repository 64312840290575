/* Module: Breadcrumbs */
.breadcrumbs {
  letter-spacing: 1px;
  @include sq-font-size(1);
  .main:not(.content-container) > & {
    margin: 0 auto;
    max-width: 1050px;
    padding: 5rem 5rem 0;
  }
}
