/* Module: S */
.mobile-header {
  > a {
    padding: .5rem;
    @include sq-font-size(.875);
    > i {
      @include sq-font-size(1.625);
    }
  }
}
.mobile-search {
  padding: 1.125rem;
  @include sq-font-size(1.125);
  &__button.button {
    padding: 1rem 1.5rem;
    @include sq-font-size(1.5);
  }
  &__input-group {
    line-height: 1;
  }
  input {
    margin-left: 1rem;
    width: 95%;
  }
}
.mobile-sign-in {
  &__input-wrapper {
    width: 100%;
    max-width: pxToRem(350);
  }
}
