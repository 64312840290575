/* Module: S */
.content-container .block-icons,
.block-icons {

    &__item {
        width: 31%;
        height: auto;
        max-height: 100%;
    
        &-content {
            padding-right: 0;
            color: $greyish-brown-four;
            padding: 0 0 pxToRem(85) 0;
        }
    }

    &__buttons {
        flex-flow: row wrap;

        a {
            min-width: pxToRem(125);
            margin-bottom: pxToRem(15);
            text-align: center;
        }
    }
}

body.member-logged-in {

    .block-icons {
        &__item {
            &-content {
                margin: 0;
                padding: 0 0 pxToRem(145) 0;
            }
        }
    }
}

