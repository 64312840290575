/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
//
// .social-tabs__element { ... }
// .social-tabs__element--modifier { ... }
// .social-tabs__sub-element { ... }

.social-tabs {
	.tabs__tab-list{
		width: 1105px;
		padding-top: 30px;
		.tabs__item{
			.tabs__link{
				font-size: 24px;
				height: 100px;
				line-height: 100px;
			}
		}
	}
	.tabs__tab-content{
		width: 1105px;
		.tabs__target{
			padding-top: 50px;
		}
		.social-list{
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			.social-item{
				flex: 1;
				border-left: 1px solid color(ocean);
				padding: 0 20px;
				&:first-child{
					border: none;
					padding-left: 0;
				}
				&:last-child{
					padding-right: 0;
				}
			}
			.social-item__left{
				width: 20%;
				img{
					max-width: 50px;
				}

			}
			.social-item__right{
				width: 78%;
				.author{
					font-size: 14px;
					line-height: 1.6;
				}
				.time{
					font-size: 14px;
					line-height: 1.6;
				}

				.content{
					font-size: 14px;
					line-height: 1.6;
					img{
						width: 100%;
					}
					iframe{
						max-width: 100%;
					}
					a.youtube{
						color: color(ocean);
					}
				}
			}
		}

		.action{
			clear: both;
			padding-bottom: 40px;
			a{
				font-size: 14px;
				line-height: 1.6;
				color: color(ocean);
				text-decoration: none;
				&:hover{
					text-decoration: underline;
				}
			}
		}

	}
}

// speical width for offcanvas
.off-canvas__content{
	.social-tabs{
		.tabs__tab-list,
		.tabs__tab-content{
			width: 80%;
		}
	}
}
