/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
//
// .icon-tiles__element { ... }
// .icon-tiles__element--modifier { ... }
// .icon-tiles__sub-element { ... }

.icon-tiles {
  padding: 50px 0;
  text-align: center;

  .icon-tiles__item {
    float: left;
    display: block;
    width: 33.3%;
    padding-bottom: 0;

    a {
      color: #000;
      text-decoration: none;

      &:hover {
        box-shadow: none;
        text-decoration: underline;
      }

      &.icon-wrapper {
        display: block;
        height: 80px;
        line-height: 80px;
        padding: 0;
        text-align: center;
      }
    }

    .text-wrapper {
      padding-left: 0;
      border: 0;
    }

    h3 {
      font-size: 24px;
      font-weight: bold;
      color: #000;
      margin: 50px auto 20px;
    }

    p {
      margin: 17px auto;
      max-width: 250px;
      font-size: 16px;
      line-height: 1.4;
      color: color(greyish-brown-three);
    }
  }
}
