/* Module: Matrix form */
.sq-form-section,
.sq-form-unattached,
.sq-form {
  .sq-form-question-datetime {
    input {
      &[type='text'] {
        display: inline-block;
        margin-right: 0.5rem;
        width: auto;
        margin-bottom: 0.5rem;
        min-width: 0;
        &[size='4'] {
          width: 5rem;
        }
      }
    }

    label,
    select {
      display: inline-block;
      margin-right: 0.5rem;
      width: auto;
      margin-bottom: 0.5rem;
      min-width: 0;
    }
  }
}

.sq-form-error,
.sq-form-required-field {
  font-size: pxToRem(14);
  line-height: 1.7;
}

.sq-form-section,
.sq-form-unattached,
.sq-form {
  textarea {
    max-height: 120px;
  }
}
