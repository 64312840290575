/* Module: Breadcrumbs */
.breadcrumbs {
  color: color(greyish-brown-three);
  margin-bottom: 1rem;
  text-transform: uppercase;
  @include sq-font-size(0.875);
  .has-subnav & {
    margin-top: 2rem;
  }
  .main:not(.content-container) > & {
    margin: 0 auto;
    max-width: calc(890px + 2rem * 2);
    padding: 2rem 2rem 0;
  }
  .breadcrumbs__link {
    color: color(greyish-brown-three);
    text-decoration: none;
    border: none;
    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
      @include sq-box-shadow(none);
    }
  }
}

.breadcrumbs__list {
  @extend %list-reset;
}

.breadcrumbs__item {
  display: inline-block;
  padding-left: 0 !important;
  margin: 0 !important;
  &:before {
    content: none !important;
    display: none !important;
  }
}


.breadcrumbs__divider {
  @include sq-inline-block;
  margin: 0 0.5rem;
}

.breadcrumbs__current {
  font-weight: bold;
}
