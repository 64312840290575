/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of 
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
// 
// .sign-in__element { ... }
// .sign-in__element--modifier { ... }
// .sign-in__sub-element { ... }

.sign-in {
  &__bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1042;
    overflow: hidden;
    position: fixed;
    background: #0b0b0b;
    opacity: 0.8;
  }

  &__wrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1043;
    position: fixed;
    outline: none !important;
    -webkit-backface-visibility: hidden;
  }

  &__container {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 8px;
    overflow: auto;
    &:before {
      display: none;
    }
  }

  &__content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 1rem auto;
    text-align: center;
    z-index: 1045;
    background-color: white;
    color: color(greyish-brown-three);
    overflow-y: auto;
  }

  &__heading {
    color: black;
    font-weight: bold;
    margin: 2.5rem 2rem 2rem;
    @include sq-font-size(1.5);
  }

  &__form-field {
    margin-bottom: 2.5rem; 
    i {
      color: color(ocean);
      margin-right: 1.5rem;
      &::before {
        @include sq-transform(unquote("scale(2) translate(3px, 1px)"));
      }
    }
  }

  &__input-wrapper {
    border-color: color(white-three);
    input {
      @include placeholder() {
        color: color(greyish-brown-three);
      }
    }
  }

  &__link {
    color: color(greyish-brown-three);
    text-decoration: none;
    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
    }
  }

  &__close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    text-decoration: none;
    text-align: center;
    color: color(greyish-brown-three);
    &:hover,
    &:focus,
    &:active {
      color: darken(color(greyish-brown-three), 10%);
    }
  }
}