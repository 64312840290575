/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
//
// .social-tabs__element { ... }
// .social-tabs__element--modifier { ... }
// .social-tabs__sub-element { ... }


.social-tabs {
	.tabs__tab-list{
		width: 90%;
		padding-top: 5px;
		margin: 0 auto;
		display: block;
		clear: both;
		.tabs__item{
    		@extend %list-reset;
			float: left;
			width: 33%;
			text-align: center;
			border-bottom: 1px solid color(white-three);

			.tabs__link{
				display: block;
				font-size: 18px;
				width: 100%;
				color: #949494;
				border: none;
				background: none;
				height: 60px;
				line-height: 60px;
				padding-bottom: 1px;
				border-bottom: 2px solid transparent;

				&::after{
					content: '';
				}

				&.uber-accordion__button-active{
					color: color(ocean);
					border-color: color(ocean);

					&::after{
						content: '';
					}
				}
			}
		}
	}
	.tabs__tab-content{
		clear: both;
		margin: 0 auto;
		display: block;
		.tabs__target{
			padding-top: 32px;
		}
		.social-list{
			height: auto;
			display: block;
			justify-content: center;
			flex-wrap: wrap;
			.social-item{
				flex: 1;
				margin-bottom: 34px;
			}
			.social-item__left{
				display: inline-block;
				width: 11%;
				vertical-align: top;
			}
			.social-item__right{
				display: inline-block;
				width: 86%;
				padding-left: 10px;
				.author{
					font-size: 13px;
					font-weight: bold;
					line-height: 1.6;
					color: color(greyish-brown-three);
				}
				.time{
					float: right;
					font-size: 14px;
					line-height: 1.6;
					text-align: right;
					color: lighten(color(ocean), 10%);
				}

				.content{
					margin-top: 7px;
					font-size: 14px;
					line-height: 1.6;
					color: color(greyish-brown-three);
					min-height: 44px;
					text-overflow: ellipsis;
					img{
                        display: block;
						width: 100%;
					}
					a.youtube{
						color: color(ocean);
					}
				}
			}
		}

		.action{
			clear: both;
			padding-bottom: 31px;
			a{
				font-size: 14px;
				line-height: 1.6;
				color: color(ocean);
				text-decoration: none;
				&:hover{
					text-decoration: underline;
				}
			}
		}

	}
}

// speical width for offcanvas
.off-canvas__content{
	.social-tabs{
		.tabs__tab-list,
		.tabs__tab-content{
			width: 90%;
		}
	}
}
