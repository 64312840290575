/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
//
// .member-nav__element { ... }
// .member-nav__element--modifier { ... }
// .member-nav__sub-element { ... }

.member-nav-toggle {
  display: none;
  @include mq-tablet() {
    @include sq-rounded(top, right);
    @include sq-rounded(bottom, right);
    @include sq-transform(rotate(180deg));
    @include sq-transition(right .5s);
    @include writing-mode(vertical-lr);
    background-color: color(light-navy);
    color: white;
    display: block;
    min-height: 7rem;
    padding: 0.5rem 1rem 1rem;
    position: absolute;
    right: 1rem;
    top: 1.35rem;
    white-space: nowrap;
    z-index: 10;
  }
  &__text {
    border-top: 1px solid color(white-45p);
    padding-top: .5rem;
  }
  &__icon {
    @include sq-transition(all .5s);
    @include writing-mode(horizontal-tb);
    padding: .25rem 0;
    .active-member-nav & {
      @include sq-transform(rotate(-180deg));
    }
  }
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
  .active-member-nav & {
    right: $off-canvas__nav-width--tablet;
  }
}
.member-nav {
  background-color: color(light-navy);

  &__header {
    @include sq-flex-box;
    background-color: #fff;
    color: #000;
    justify-content: space-around;
    align-items: center;
  }

  &__logo {
    font-size: 1rem;
    margin: 1.3125rem 0;
    img {
      border-right: 1px solid color(purple-brown);
      height: auto;
      margin-right: .5rem;
      max-width: 50px;
      padding-right: .5rem;
      vertical-align: middle;
      width: 100%;
    }
  }

  &__sign-out {
    &.button {
      @include sq-font-size(.75);
      min-width: 0;
      padding: .75rem 1.25rem;
      @include mq-tablet() {
        display: none;
      }
    }
  }

  &__menu {
    padding: 0 2rem;
    > .member-nav__list {
      border-bottom: 1px solid $member-nav__separator-color;
      padding-bottom: 2rem;
    }
  }

  &__name {
    @include sq-font-size(1.75);
    @include sq-rem-attr(margin-bottom, 1.625);
    color: #fff;
    font-weight: bold;
    text-align: center;
    word-wrap: break-word;
  }

  &__warning {
    text-align: center;
  }

  &__list {
    color: #fff;
    list-style: none;
    margin: 1.625rem auto;
    padding: 0;

    ul {
      margin-bottom: 0;
    }

    li:not(.member-nav__list-category) {
      margin-top: 1.75rem;
    }

    a {
      @include sq-font-size(.875);
      color: #fff;
      display: block;
      font-weight: bold;
      text-decoration: none;
      text-transform: uppercase;

      &:hover,
      &:active,
      &:focus {
        text-decoration: underline;
      }
    }

    i {
      @include sq-font-size(1.25);
      @include sq-rem-attr(margin-right, .75);
      color: color(butterscotch);
      float: left;
      vertical-align: top;

      &::before {
        vertical-align: top;
      }
    }

    strong + i {
      @include sq-rem-attr(margin-left, .75);
      float: none;
    }
  }

  &__list-category {
    border-bottom: 1px solid $member-nav__separator-color;
    padding: 1.625rem 0;
    &:first-child {
      border-top: 1px solid $member-nav__separator-color;
    }
    li {
      @include sq-rem-attr(padding-left, 2.375);
      a {
        @include sq-font-size(.8125);
        font-weight: normal;
      }
    }
    i {
      @include sq-font-size(1.5);
    }
  }

  &.off-canvas__nav .member-nav__logo {
    @include mq-tablet() {
      margin: 2.85rem auto;
    }
  }
}

.active-mobile-member-nav {
  .off-canvas__content,
  .mobile-header {
    left: -$off-canvas__nav-width;
    @include mq-tablet() {
      left: 0;
    }
  }
}
