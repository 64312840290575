/* Module: Off canvas nav */

.off-canvas__nav {
  // Position fixed in the first instance, but when used inside .off-canvas__container this
  // will be changed to position: absolute;
  position: fixed;
  visibility: hidden;
  overflow-x: hidden;
  overflow-y: scroll;
  top: 0;
  bottom: 0;
  width: $off-canvas__nav-width;
  background-color: $off-canvas__nav-bg;
  z-index: 10;
  color: $off-canvas__nav-text;

  &.off-canvas__nav--left {
    left: 0;
    @include sq-transform(translateX(-100%));
  }

  &.off-canvas__nav--right {
    right: 0;
    @include sq-transform(translateX(100%));
    @include mq-tablet() {
      right: 1rem;
    }
  }

  &.off-canvas--active {
    visibility: visible;
    @include sq-transition(unquote("transform 0.5s, visibility 0.5s 0s"));

    // Left aligned
    &.off-canvas__nav--left {
      @include sq-transform(translateX(0%));
      // & ~ .off-canvas__content {
      //   left: $off-canvas__nav-width;
      // }
    }

    // Right aligned
    &.off-canvas__nav--right {
      @include sq-transform(translateX(0%));
      @include mq-tablet() {
        @include sq-transform(translateX(100%));
        @include sq-transition(transform 0.5s);
      }
      .active-member-nav & {
        @include mq-tablet() {
          @include sq-transform(translateX(0%));
          right: 0;
        }
      }
      // & ~ .off-canvas__content {
      //   left: -$off-canvas__nav-width;
      // }
    }
  }
  @include sq-transition(unquote("transform 0.5s, visibility 0s 0.5s"));

  @include mq-tablet() {
    @include sq-transition(transform 0.5s);
    position: absolute;
    visibility: visible;
    width: $off-canvas__nav-width--tablet;
  }
}

.off-canvas__content {
  left: 0;
  // min-width: 100%;
  min-height: 100%;
  position: relative;
  // padding: 1em;
  @include sq-border-box();
  @include sq-transition(left 0.5s);
  @include mq-tablet() {
    @include sq-transition(initial);
    overflow-y: hidden;
    position: relative;
    width: 100%;
  }
}

// Use the container if you need to either restrict the 'off canvas' part of the nav or have the
// content position change as the
.off-canvas {
  position: relative;
  min-height: 100%;

  // & .off-canvas__nav {
  //   position: absolute;
  // }

  // Active state
  & .off-canvas--active {

      // Position the content
      // & ~ .off-canvas__content {
      //     position: absolute;
      // }
    }
  }
