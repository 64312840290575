/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of 
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
// 
// .footer-social__element { ... }
// .footer-social__element--modifier { ... }
// .footer-social__sub-element { ... }

.footer-social {
  .footer__container {
    padding-top: 52px;
	}

  .footer-social__subscribe {
    padding-left: 0;
    padding-right: 0;

    p {
      font-size: 24px;

    }
    label {
      margin-bottom: 20px;
		}

    .input-wrapper {
      width: 360px;
      font-size: 20px;
		}

    .button {
      font-size: 20px;
		}
  }

  .footer-social__icons {
    margin-top: 46px;
    width: 532px;

    a {
      font-size: 43px;
      margin-right: 40px;
		}
	}
}
