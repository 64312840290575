/* Module: S */
.box-list {
  &__wrapper {
    margin: 1rem 0;
  }
  &__link {
    padding: 0;
    font-size: 1rem;
  }
  &__item {
    display: block;
    margin: 0;
    width: auto;
    height: auto;
    .box-list__link {
      height: auto;
      width: auto;
    }
  }
}