/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
//
// .feedback__element { ... }
// .feedback__element--modifier { ... }
// .feedback__sub-element { ... }
