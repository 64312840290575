/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
//
// .results-list__element { ... }
// .results-list__element--modifier { ... }
// .results-list__sub-element { ... }

.results-list {
  &__badge {
    position: absolute;
    float: none;
    right: 1rem;
    top: 0;
  }
  &__metadata {
    display: inline-block;
    min-width: 185px;
    vertical-align: top;
    width: 30%;
  }
  &__more-wrapper {
    text-align: right;
  }
  .results-list__author,
  .results-list__summary {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}

.pagination {
  li {
    a {
      margin: 0 0.5rem;
    }
  }
}

.facet {
  max-width: 16rem;
}

.results-list .applied-facets__clear-all.button {
  display: inline-block;
  float: right;
}
