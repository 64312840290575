/* Module: S */
.content-wrapper .list-resources,
.list-resources {

    border-top: pxToRem(1) solid $very-light-pink-four;
    // padding-top: pxToRem(28);

    &__item {

        margin: 0;
        padding: pxToRem(30) 0 pxToRem(20) 0;
        border-bottom: pxToRem(1) solid $very-light-pink-four;
        position: relative;

        // &:first-child {
            // padding-top: 0;
        // }

        &-title,
        &-title a {
            
            font-size: pxToRem(18);
            line-height: pxToRem(23);
            color: $black;
            font-weight: 600;

            &:hover {
                @include sq-box-shadow(none);
            }
        }

        &-type {
            margin: 0 0 pxToRem(15) 0;
            font-size: pxToRem(14);
            line-height: pxToRem(17);
            color: $light-navy;

            a {
                
                color: $light-navy;
                border: none;

                &:focus,
                &:hover {
                    @include sq-box-shadow(none);
                    text-decoration: underline;
                }
            }
        }

        &-title {
            margin: 0 0 pxToRem(10) 0;

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }

        &-content {
            color: $greyish-brown;
        }

    }

    &__member-tag {
        @include font-smoothing;
        position: absolute;
        width: auto;
        color: $white;
        text-transform: uppercase;
        border-left: pxToRem(5) solid $butterscotch;
        background-color: $light-navy;
        font-size: pxToRem(13);
        line-height: pxToRem(15);
        padding: pxToRem(6) pxToRem(11);
        right: 0;
        top: 0;
        // bottom: pxToRem(-28);
    }
    
}