/* Module: S */
.link-block {
  .link-block__title,
  .link-block__description {
    padding: pxToRem(15);
  }
}

@media screen and (min-width: 768px) {
  .links-block {
    display: flex;
  }

  .link-block {
    width: 50%;
    max-height: pxToRem(200);
    overflow: hidden;
    &--inner {
      display: flex;
      height: 100%;
      flex: 1;
      text-align: center;
    }

    .link-block__title,
    .link-block__description {
      display: inline-block;
      border-radius: 0;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    .link-block__title {
      position: relative;
      z-index: 1;
      width: calc(34% - 20px);

      &:before,
      &:after {
        content: '';
        display:block;
        position: absolute;
        right: -20%;
        z-index: -1;
        width: pxToRem(50);
        height: 50%;
        background-color: color(butterscotch);
      }

      &:before {
        transform: skew(20deg);
        top: 0;
      }
  
      &:after {
        transform: skew(160deg);
        bottom: 0;
      }
    }

    .link-block__description {
      width: 66%;
      border-width: pxToRem(1);
    }
  }

  .link-block--left {
    .link-block__title {
      border-top-left-radius: pxToRem(20);
      border-bottom-left-radius: pxToRem(20);
      padding-right: 0;
      padding-left: pxToRem(5);
    } 

    .link-block__description {
      padding-left: 10%;
    }
  }

  .link-block--right {
    
    .link-block--inner {
      flex-direction: row-reverse;
    }

    .link-block__title {
      border-top-right-radius: pxToRem(20);
      border-bottom-right-radius: pxToRem(20);
      padding-right: pxToRem(5);
      padding-left: 0;

      &:before,
      &:after {
        right: auto;
        left: -20%;
      }

      &:before{
        top: auto;
        bottom: 0;
      }

      &:after {
        bottom: auto;
        top: 0;
      }
    }
    
    .link-block__description {
      padding-right: 10%;
    }
  }
}

@media screen and (min-width: 768px)  and (max-width: 1000px){ 
  .links-block {
    display: block;
  }

  .link-block {
    width: 100%;

    .link-block__title {
      width: 50%;
    }

    .link-block__description {
      width: 100%;
    }
  }

  .link-block--left {
    .link-block__title {
      padding-left: pxToRem(15);

      &:before,
      &:after {
        right: auto;
        right: -10%;
      }
    } 
  }

  .link-block--right {
    .link-block__title {
      padding-right: pxToRem(15);

      &:before,
      &:after {
        right: auto;
        left: -10%;
      }
    } 
  }
}

.links-block {
  &.theme--navy {
    .link-block__title {
      &:before,
      &:after {
        background-color: color(guild-navy);
      }
    }
  }

  &.theme--teal {
    .link-block__title {
      &:before,
      &:after {
        background-color: color(guild-teal);
      }
    }
  }
}