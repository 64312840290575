// Convert a pixel value to EM units
@function pxToEm($pixel) {
  @return $pixel/$base-font-pixel + em;
}

// Convert a pixel value to REM units
@function pxToRem($pixel) {
  @return $pixel/$base-font-pixel + rem;
}

// Strip unit taken from foundation
@function stripUnit($num) {
  @return $num / ($num * 0 + 1);
}
