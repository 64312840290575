/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
//
// .social-tabs__element { ... }
// .social-tabs__element--modifier { ... }
// .social-tabs__sub-element { ... }

.social-tabs {
	.tabs__tab-list{
		padding-top: 25px;
		.tabs__item{
			.tabs__link{
				font-size: 40px;
				height: 120px;
				line-height: 120px;
			}
		}

	}
	.tabs__tab-content{

		.tabs__target{
			padding-top: 60px;
		}
		.social-list{

			.social-item__right{

				.author{
                    font-size: 16px;
					line-height: 1.5;
				}
				.time{
					font-size: 16px;
					line-height: 1.5;
					color: color(ocean);
				}

				.content{
					font-size: 16px;
					line-height: 1.5;
					img{
						max-width: 600px;
					}
				}
			}
		}


		.action{
			a{
				font-size: 16px;
			}
		}
	}
}
