/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of 
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
// 
// .partners__element { ... }
// .partners__element--modifier { ... }
// .partners__sub-element { ... }

.partners {
  padding-top: 40px;
  padding-bottom: 30px;

  .row {
    width: 45%;
    display: inline-block;
    vertical-align: top;
  }

  .logo {
    display: block;
    margin: 0 auto 30px;
    width: 90%;

    &:hover {
      text-decoration: none;
    }
  }
}

// speical width for offcanvas
.off-canvas__content {

  .partners {
    .row {
      width: 45%;
      display: inline-block;
      vertical-align: top;
    }

    .logo {
      max-width: 160px;
    }
  }
}
