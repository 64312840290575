/* Module: S */
.intro-resource {

    margin: pxToRem(35) 0 pxToRem(46) 0;

    &__wrapper {
        background-size: 0;
    }

    &__title {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left top;
        background-size: pxToRem(72);
        padding: 0 0 0 pxToRem(87);
        min-height: pxToRem(72);
        margin: 0 0 pxToRem(17) 0;
        font-size: pxToRem(27);
        line-height: pxToRem(32);
        font-weight: 600;
        color: $black;
        width: 100%;
        max-width: pxToRem(283);
    }

    &__content {

        p {
            font-size: pxToRem(14);
            line-height: pxToRem(24);
            color: $dark-grey;
            font-weight: 400;
        }
        
    }

    &__cta {
        margin: pxToRem(15) 0 0 0;
    }

}