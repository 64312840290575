/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass
// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.
// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
//
// .tiles__element { ... }
// .tiles__element--modifier { ... }
// .tiles__sub-element { ... }
.tiles {
	
	&__bottom-link {
		text-transform: uppercase;
	}

	&__item {
		
		@include sq-flex-box();
		flex-direction: column;
		text-align: center;
		padding: 30px;

		.action {
			margin-top: 25px;
		}

		h3 {
			font-size: 18px;
			font-weight: 500;
			margin: 0 auto 20px;
		}

		.date {
			margin: 20px auto 12px;
			font-size: 14px;
			line-height: 1.6;
			text-transform: uppercase;
		}

		&-content {
			margin: 0 auto;
			h4 {
				line-height: 1.6;
				font-weight: normal;
				font-size: 16px;
			}

			a {
				color: $white;
			}
		}

		.more-link {
			align-self: flex-end;
	
			a {
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
			}
		}

		.bottom-links {
			display: block;
			text-align: center;
			margin-top: 40px;
			width: 100%;
			.tiles__bottom-link {
				display: block;
				width: 100%;
				margin-top: 10px;

				a {
					text-align: left;
					color: white;
					
					.icon-2x:before {
						vertical-align: -0.6rem;
					}
					span {
						width: 50%;
						display: inline-block;
						vertical-align: middle;
						font-size: 14px;
					}
				}
			}
		}

		&.split-container {
			.split-image {
				display: none;
			}
		}
		
		// event item with big date style
		&.event {
			background-color: white;
			.big-date {
			font-size: 40px;
			font-weight: 500;
			margin: 0 auto 20px;
			}
			.sub-heading {
			font-size: 15px;
			font-weight: 500;
			margin-top: 20px;
			}
			.tiles__item-content {
			margin-top: 20px;
			}
			.social-links {
			margin-top: 20px;
			a.circle {
				margin: 0 9px;
				font-size: 20px;
				color: color(ocean);
				width: 60px;
				height: 60px;
				line-height: 60px;
				border-color: color(ocean);
				&:hover {
				color: white;
				}
			}
			}
		}

		&.info {
			h2 {
				margin-bottom: 20px;
			}
			
			p {
				margin: 20px auto;
			}
			
			ul {
				
				padding: 0;
				margin: 20px auto;
				list-style: none;

				li {
					font-weight: 500;
					margin: 0.5rem 0;
				}

			}
			.action {
				margin-top: 40px;
			}
		}
	}
}
