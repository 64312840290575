/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
//
// .tiles__element { ... }
// .tiles__element--modifier { ... }
// .tiles__sub-element { ... }

.tiles {
  @include sq-flex-box();
  align-items: stretch;
  &.two-cols {
    .tiles__item{
      width: 50%;
    }
  }
  &.three-cols{
    .tiles__item{
      width: 33.33%;
    }
  }
  &__item {
    @include sq-flex(1 1 33%);
    text-align: center;
    padding: 40px;

    .action {
      margin-top: 63px;
      margin-bottom: 0;
      .button {
        padding: 15px 30px;
        font-size: 14px;
      }
    }
    h3 {
      font-size: 24px;
      font-weight: 500;
      margin: 25px auto;
    }
    .date {
      margin: 30px auto 12px;
      font-size: 14px;
      line-height: 1.6;
    }
    &-content {
      margin: 0 auto;
      font-size: 16px;
      h4 {
        line-height: 1.6;
        font-weight: normal;
        font-size: 18px;
      }
    }
    .more-link {
      align-self: flex-end;
      a {
        font-size: 14px;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .bottom-links {
      justify-content: space-between;
      text-align: center;
      margin-top: 40px;
      @include sq-flex-box();
      .tiles__bottom-link {
        a {
          justify-content: center;
          text-align: left;
          color: white;
          @include sq-flex-box();
          span {
            display: inline-block;
            vertical-align: middle;
            width: 100px;
            font-size: 14px;
          }
          .icon-arrow:before {
            margin-left: 0;
          }
        }
      }
    }
    &.split-container {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      flex-direction: row;
      padding: 0;
      .split-image {
        display: block;
        -ms-flex: 1 1 42%;
        -webkit-flex: 1 1 42%;
        flex: 1 1 42%;
        height: 100%;
        background-color: color(purple-brown);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
      }
      .split-text {
        -ms-flex: 1 1 58%;
        -webkit-flex: 1 1 58%;
        flex: 1 1 58%;
        text-align: left;
        padding: 40px 20px;
        h3 {
          margin: 0 0 16px;
        }
        .date {
          margin: 20px auto;
        }
        .line.center {
          margin: 0;
        }
      }
    }
    // event item with big date style
    &.event {
      background-color: white;
      .big-date {
        font-size: 4.5rem;
        max-width: 433px;
        font-weight: 500;
        margin: 14px auto 20px;
      }
      .sub-heading {
        font-size: 24px;
        font-weight: 500;
        margin-top: 20px;
      }
      .tiles__item-content {
        margin-top: 20px;
        max-width: 530px;
      }
      .social-links {
        margin-top: 20px;
        a.circle {
          margin: 0 9px;
          font-size: 20px;
          color: color(ocean);
          width: 60px;
          height: 60px;
          line-height: 60px;
          border-color: color(ocean);
          &:hover {
            color: white;
          }
        }
      }
      .action {
        margin-top: 30px;
      }
    }
    &.info {
      h2 {
        margin-bottom: 20px;
      }
      p {
        margin: 20px auto;
      }
      ul {
        padding: 0;
        margin: 20px auto;
        list-style: none;
        li {
          margin: 0.5rem 0;
        }
      }
      .action {
        margin-top: 40px;
      }
    }
    &.tiles__item--height-auto {
      height: auto;
      max-height: none;
    }
  }
}
