/* Module: S */
.social-blocks {

    &__item {
        width: 50%;
        padding: pxToRem(40) pxToRem(20);

        &--youtube {
            min-height: pxToRem(400);

            &:before {
                height: 70%;
                opacity: 0.95;
            }

            .social-blocks__item-footer {
                position: relative;
                padding: 0;
            }
        }

        &-content {
            padding-bottom: pxToRem(90);
        }


        &-footer {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: pxToRem(40) pxToRem(20) pxToRem(40) pxToRem(20);
        }
    }

}