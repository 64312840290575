/* Module: S */
ul:not([class]) {
    @include sq-list-reset();
    >li {
        position: relative;
        margin: 0.5rem 0;
        padding-left: 1.3125rem;
        &:before {
            content: "";
            display: inline-block;
            position: absolute;
            width: 5px;
            height: 5px;
            top: 10px;
            left: 0;
            background-color: color(light-navy);
            @include sq-border-radius(50%);
        }
        ul:not([class])>li:before {
            background-color: transparent;
            border: 1px solid color(light-navy);
        }
    }
}

ol:not([class]) {
    @include sq-list-reset();
    counter-reset: pg-ol;
    >li {
        position: relative;
        margin: 1rem 0;
        padding-left: 1.3125rem;
        &:before {
            counter-increment: pg-ol;
            content: counter(pg-ol) '.';
            display: inline-block;
            position: absolute;
            left: 0;
            text-align: right;
            color: color(light-navy);
        }
    }
}