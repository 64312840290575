/* Module: S */
// Forms
.sq-form {
  fieldset {
    margin-top: 20px;
  }
}

input {
  color: inherit;
}

.input-group {
  display: table;
  width: 100%;
}

.input-wrapper {
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
  vertical-align: middle;
  padding: .875rem;

  input {
    background: none;
    border: 0;
    color: #000;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .input-group & {
    display: table-cell;
    padding: 0 .875rem;

    &.round-left {
      border-right-width: 0;
    }

    &.round-right {
      border-left-width: 0;
    }
  }
}


.content-container {

  select {
    &.picker__select--year,
    &.picker__select--month {
      width: auto;
    }
    &:not(.picker__select--year, .picker__select--month) {
      @include sq-border-radius(0)
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      &::-ms-expand { // for IE 11
        display: none;
      }

      width: 100%;
      color: color(black-two);
      background-color: color(white-three);
      border: 1px solid #bbb;
      padding: 1em;
      padding-right: 3em;
      background-image: url('{{file_dest}}/dropdown-triangle.png');
      background-position: 95% center;
      background-repeat: no-repeat;
      background-size: 8px 5px;
      line-height: 1.3;
    }
  }

  label {
    font-weight: normal;

    &.input-label {
      display: block;
      line-height: 2;
      color: color(black-two);
      font-weight: normal;
    }
  }

  input {
    margin-bottom: 0;

    &.input-control {
      width: 100%;
      background: #fff;
      color: #000;
      border: 1px solid #bbb;
      padding: 16px;

      &:active,
      &:focus {
        outline: none;
        border-left: 4px solid color(light-navy);
        padding-left: 13px;
      }

      &[readonly='readonly'] {
        background-color: #ddd;
      }
      // &:invalid{
      //   border-left: 4px solid red;
      //   padding-left: 13px;
      // }
    }
  }

  textarea {
    &.input-control {
      width: 100%;
      background: #fff;
      color: #000;
      border: 1px solid #bbb;
      padding: 11px 18px;
      height: 120px;
    }
    &:active,
    &:focus {
      outline: none;
      border-left: 4px solid color(light-navy);
      padding-left: 15px;
    }
    &[readonly='readonly'] {
      background-color: #ddd;
    }
  }

} //.content-container

.inline-field {
  margin-top: .5rem;
}

// fake checkbox/radio input common
%checkbox-common {
  position: relative;
  margin: 0;
  padding-left: 1.875rem;
  font-weight: normal;
  &:before {
    content: '';
    position: absolute;
    width: 19px;
    height: 19px;
    top: 0;
    left: 0;
    background-color: color(white-three);
    border: 1px solid $matrix-form__border-colour;
  }
  &:hover:before {
    background-color: darken(color(white-three), 5%);
  }
  &:active:before,
  &:focus:before {
    border-color: color(light-navy);
  }
}
%checkbox-checked-after-common {
  content: '';
  position: absolute;
}

// fake checkboxes
.checkbox {
  @extend %checkbox-common;
  &.checked {
    &:after {
      @extend %checkbox-checked-after-common;
      width: 10px;
      height: 19px;
      top: -5px;
      left: 8px;
      border: solid color(light-navy);
      border-width: 0 4px 4px 0;
      @include sq-transform(rotate(45deg));
    }
  }
}

// fake radio inputs
.radio {
  @extend %checkbox-common;
  &:before {
    @include sq-border-radius(50%);
  }
  &.checked {
    &:after {
      @extend %checkbox-checked-after-common;
      width: 9px;
      height: 9px;
      top: 5px;
      left: 5px;
      background-color: color(light-navy);
      @include sq-border-radius(50%);
    }
  }
}
