/**
 * version: {{version}}
 * file:    {{file}}
 * author:  Squiz Australia
 * updated: {{date}}
 *
 * Table of Contents
 *
 * - Reset
 * - Utilities
 * - General
 * - Content
 * - Modules
    {{toc}}
 */

// These imports have no CSS output, but provide variables, mixins and helpers to aid in construction of
// a design cutup.
@import "boilerplate.scss";

/*
--------------------
Reset (normalize.css)
--------------------
*/

@import "normalize.scss";

// This import disabled by default due to rendering issues, particularly with IE where some text doesn't render at all
//@import "normalize-opentype.scss";

// Box size all the things
// https://squizmap.squiz.net/imp-bp/6400
// http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
// == Start box-sizing reset ==
*, *:before, *:after {
    -webkit-box-sizing: inherit;
       -moz-box-sizing: inherit;
            box-sizing: inherit;
}
html {
    @include sq-border-box;
}
// == End box-sizing reset ==

/*
--------------------
Utilities
--------------------
*/

@import "utilities.scss";

/*
--------------------
General
--------------------
*/

// Taken from HTML 5 BP
::-moz-selection {
    background: $content-selection-highlight;
    text-shadow: none;
}

::selection {
    background: $content-selection-highlight;
    text-shadow: none;
}

html,
body {
    height: 100%;
}
body {
    overflow-y: scroll;
    font-size: $base-font-scale + em;
    line-height: 1.4;
    padding: 0;
    margin: 0;

    &.overflow-hidden {
        .off-canvas__content {
            overflow: hidden;
        }
    }
}

img {
    max-width: 100%;
    height: auto;
}

// Reset fonts for relevant elements (taken from bootstrap)
input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

// IE10 Metro responsive
// Required for Windows 8 Metro split-screen snapping with IE10
// Source: http://timkadlec.com/2012/10/ie10-snap-mode-and-responsive-design/
@-ms-viewport{
    width: device-width;
}

// Print style that shouldn't belong to print.scss to avoid the css beautifier from killing
// the @ syntax inside media queries
@page {
    margin: 0.5cm;
}

/*
--------------------
Content
--------------------
*/

// You can wrap the content styles in a class by un-commenting the following lines
// to nest the content.scss styles inside .content
//.content {
    @import "content.scss";
//}


/*
--------------------
Modules
--------------------
*/

.section {

    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;


    @media screen and (min-width: 1000px){
        flex-flow: row nowrap;
    }

    &__col {
        width: 100%;

        &--left {
            display: none;
            width: 100%;
            max-width: pxToRem(280);
            background: $white;
            position: relative;

            @media screen and (min-width: 1000px){
                display: flex;
                align-content: stretch;
                align-items: stretch;
            }
        }

        &--right {
            
            width: 100%;

        }

    }
    
    .main#main {
        padding: pxToRem(32);
        border: none;
        max-width: 100%;
        margin: 0;
        display: flex;
        justify-content: center;

        @media screen and (min-width: 1000px){
            padding: pxToRem(75) pxToRem(85) pxToRem(43) pxToRem(85);
        }
    }
}

.sub-nav--noline {
    .nav__item--active:after {
        content: '';
        display: none;
        width: 0;
        height: 0;
        background-color: transparent;
        margin: 0;
    }

    .nav__item--current:after {
        content: '';
        display: none;
        width: 0;
        height: 0;
        background-color: transparent;
        margin: 0;
    }
}

/*
---------------------------------
OLD CSS styles for NEWS and Latest BLOG sections
---------------------------------
*/

.latest-news {
    clear: both;
    width: 100%;
    background-color: color(white-two);
    background-image: url('{{file_dest}}/latest-news-mobile.png');
    background-repeat: no-repeat;
    background-position: -20px 50%;
    background-size: auto 72%;

    h3 {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        color: black;
        margin: 0 auto 20px;
    }

    .news-cols {
        margin-top: 24px;
        display: block;
        width: 100%;

        .news-col {
            display: block;
            width: 100%;
            vertical-align: top;

            .news-item {
                width: 245px;
                margin: 0 auto 30px;
                padding-left: 50px;

                h4 {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1.1;
                    margin-bottom: 10px;
                    color: $greyish-brown-four;

                    a {
                        color: $greyish-brown-four;
                    }
                }

                .date {
                    margin-top: 10px;
                    font-size: 12px;
                    line-height: 1.6;
                    color: color(greyish-brown-two);
                    text-transform: uppercase;
                }

                .description {
                    display: none;
                    margin-top: 30px;

                    .more {
                        padding-left: 10px;
                        padding-right: 10px;
                        text-decoration: none;
                        font-size: 20px;
                        vertical-align: text-top;
                        color: color(ocean);

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

            }

        }

        //.news-col
    }

    // .news-cols

    .action {
        clear: both;
        margin-top: 30px;
        text-align: center;

        .button {
            font-size: 12px;
        }
    }

}

.latest-news,
.latest-events,
.latest-training {
    padding-top: 31px;
    padding-bottom: 50px;
}

.off-canvas--active+.off-canvas__content .latest-news {
    background-image: none;
}
