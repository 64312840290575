/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
//
// .member-nav__element { ... }
// .member-nav__element--modifier { ... }
// .member-nav__sub-element { ... }

.member-nav-toggle {
  display: none;
}

.member-nav {
  &__header {
    border-bottom: none;
  }
  &__sign-out {
    display: none;
  }
  &__menu {
    padding: 0 2rem;
  }
  &.off-canvas__nav .member-nav__logo {
    margin: 2rem auto;
    @include sq-font-size(1.625);
  }
}
