/* Module: Matrix form */
.sq-form-section,
.sq-form-unattached,
.sq-form {
  select {
    width: pxToRem(350);
  }

  input {
    max-width: pxToRem(352);
    width: auto;

    &[type='file'] {
      max-width: pxToRem(350);
    }
  }
}


