/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
//
// .box-list__element { ... }
// .box-list__element--modifier { ... }
// .box-list__sub-element { ... }

// Single column, fluid size boxes by default
.box-list {
  font-size: 0;
  &__wrapper {
    margin: 0 auto;
    text-align: center;
  }
  &__link {
    display: block;
    font-size: 1.375rem;
    font-weight: bold;
    line-height: 1.4;
    border: none;
    padding: 2rem;
    text-decoration: none;
    // max-width: 18.125rem;
    &:after {
      content: "";
      display: block;
      width: 80px;
      height: 1px;
      margin-top: 0.875rem;
      background-color: color(butterscotch);
      @include sq-transition(width 0.3s, height 0.3s);
    }
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      @include sq-box-shadow(none);
      &:after {
        width: 100%;
        height: 2px;
      }
    }
  }
  &__item {
    display: inline-block;
    margin: 0.5rem 0;
    text-align: left;
    vertical-align: top;
    background-color: color(light-navy);
    width: 100%;
    // max-width: 18.125rem;
    height: auto;
    .box-list__link {
      color: white;
    }
    &:nth-child(3n+2) {
      background-color: color(white-three);
      .box-list__link {
        color: color(greyish-brown-three);
        &:after {
          background-color: color(greyish-brown-three);
        }
      }
    }
    &:nth-child(3n+3) {
      background-color: color(ocean);
      .box-list__link {
        color: white;
        &:after {
          background-color: color(butterscotch);
        }
      }
    }
    .js-enabled & {
      &:nth-child(n+4) {
        display: none;
      }
    }
  }
  &__more-wrapper {
    margin: 0 auto;
    padding: 1.5rem 0;
    .no-js & {
      display: none;
    }
  }
}

// 2 columns
@media (min-width: 43.75rem) and (max-width: 93.75rem) {
  @at-root .off-canvas--active + .off-canvas__content .main.content-container {
    .box-list {
      &__wrapper {
        max-width: 40rem;
      }
      // // If you want the 9th item left aligned instead of centered in two columns
      // &__item {
      //   &:last-child {
      //     position: relative;
      //     left: -9.7rem;
      //   }
      // }
      &__item {
        .js-enabled & {
          &:nth-child(n+4) {
            display: inline-block;
          }
          &:nth-child(n+5) {
            display: none;
          }
        }
        &:nth-child(1),
        &:nth-child(4),
        &:nth-child(7) {
            background-color: color(light-navy);
            .box-list__link {
                color: white;
                &:after {
                    background-color: color(butterscotch);
                }
            }
        }
        &:nth-child(2),
        &:nth-child(5),
        &:nth-child(8) {
          background-color: color(white-three);
          .box-list__link {
            color: color(greyish-brown-three);
            &:after {
              background-color: color(greyish-brown-three);
            }
          }
        }
        &:nth-child(3),
        &:nth-child(6),
        &:nth-child(9) {
          background-color: color(ocean);
          .box-list__link {
            color: white;
            &:after {
              background-color: color(butterscotch);
            }
          }
        }
      }
    }
  }
}

// 3 columns
@media (min-width: 67.5rem) {
  .box-list {
    &__wrapper {
      max-width: 57.5rem;
    }
    &__item {
      &:nth-child(1),
      &:nth-child(5),
      &:nth-child(9) {
        background-color: color(light-navy);
        .box-list__link {
          color: white;
          &:after {
            background-color: color(butterscotch);
          }
        }
      }
      &:nth-child(2),
      &:nth-child(6),
      &:nth-child(7) {
        background-color: color(white-three);
        .box-list__link {
          color: color(greyish-brown-three);
          &:after {
            background-color: color(greyish-brown-three);
          }
        }
      }
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(8) {
        background-color: color(ocean);
        .box-list__link {
          color: white;
          &:after {
            background-color: color(butterscotch);
          }
        }
      }
      // // Reset 9th item in three columns
      // &:last-child {
      //   position: inherit;
      // }
      .js-enabled & {
        &:nth-child(n+5) {
          display: inline-block;
        }
        &:nth-child(n+7) {
          display: none;
        }
      }
    }
  }
}
