/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
//
// .tiles__element { ... }
// .tiles__element--modifier { ... }
// .tiles__sub-element { ... }

.tiles {
  &__item {
    padding: 60px;
    h3 {
      font-size: 30px;
      font-weight: 500;
      margin: 0 auto 36px;
    }
    .date {
      margin: 26px auto 12px;
      font-size: 22px;
      line-height: 1.6;
    }
    &-content {
      margin: 0 auto;
      h4 {
        line-height: 1.4;
        font-weight: normal;
        font-size: 24px;
      }
    }
    .bottom-links {
      justify-content: space-between;
      align-items: center;
      text-align: center;
      margin-top: 40px;
      width: 100%;
      @include sq-flex-box();
      .tiles__bottom-link {
        a {
          text-align: left;
          color: white;
          justify-content: center;
          align-items: center;
          @include sq-flex-box();
          span {
            width: 100px;
            font-size: 14px;
          }
        }
      }
    }

    &.event {
      background-color: white;
      .big-date {
        font-size: 80px;
        font-weight: 500;
        margin: 14px auto 20px;
      }
      .sub-heading {
        font-size: 30px;
        font-weight: 500;
        margin-top: 20px;
      }
      .tiles__item-content {
        margin-top: 20px;
      }
      .social-links {
        margin-top: 20px;
        a.circle {
          margin: 0 9px;
          font-size: 25px;
          color: color(ocean);
          border-color: color(ocean);
          &:hover {
            color: white;
          }
        }
      }
    }
  }
}
