/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
//
// .results-list__element { ... }
// .results-list__element--modifier { ... }
// .results-list__sub-element { ... }

.results-list {
  @extend %clearfix;
  &__count {
    margin: 1.5rem 0;
  }
  &__list {
    @include sq-list-reset();
  }
  &__item {
    position: relative;
    padding: 1rem 0;
    border-top: 1px solid color(pinkish-grey);
    &:last-child {
      border-bottom: 1px solid color(pinkish-grey);
    }
    p {
      margin: 0.5rem 0;
    }
  }
  &__badge {
    position: relative;
    top: -1rem;
    float: right;
    margin-left: 100%;
    white-space: nowrap;
  }
  &__date {
    clear: right;
    font-size: pxToRem(13);
  }
  &__metadata {
    border: 1px solid color(pinkish-grey);
    margin: 0.5rem;
    padding: 1.5rem 1rem;
    position: relative;
  }
  &__metadata-key {
    font-size: 0.85rem;
    position: absolute;
    top: 0.25rem;
    left: 0.5rem;
  }
  &__metadata-value {
    text-align: center;
    font-weight: bold;
  }
  &__more-wrapper {
    margin: 0 auto;
    text-align: center;
    > .button,
    > .button + .button.button {
      margin: 0.5rem;
      margin-left: 0.5rem;
    }
  }
  .results-list__title {
    line-height: 1.4;
    margin: 0.5rem 0;
    a {
      color: color(black-two);
    }
  }
  .results-list__author,
  .results-list__summary {
    @extend %screen-hide;
  }
  @include mq-tablet() {
    &:before,
    &:after {
      content: '';
      display: table;
      line-height: 0;
    }
    &:after {
      clear: both;
    }
    *zoom: 1;
    &__main {
      margin-left: 30%;
    }
    .target-audience &__main{
      margin-left: 0%;
    }
    &__menu {
      float: left;
      width: 25%;
    }
    .results-list__header {
      margin-top: 0;
      line-height: 1.5;
    }
  }
}

.pagination {
  text-align: center;
  margin: 1.5rem 0;
  padding: 0;
  li {
    display: inline-block;
    a {
      display: inline-block;
      height: 2rem;
      line-height: 2;
      min-width: 2rem;
      margin: 0;
      padding: 0;
      color: color(greyish-brown-three);
      border: 1px solid transparent;
      @include sq-border-radius(50%);
      &:hover,
      &:focus,
      &:active {
        border-color: color(ocean);
        @include sq-box-shadow(none);
      }
    }
    &.active a {
      border-color: color(ocean);
    }
  }
}

.facet {
  border: 1px solid color(pinkish-grey);
  .facet__label {
    background-color: color(white-three);
    font-size: 1.25rem;
    font-weight: bold;
    padding: 0.5rem 1rem;
    margin-top: 0;
    + a {
      display: inline-block;
      margin: 1rem 0 1rem 1rem;
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
  .facet__body {
    padding: 0 1rem;
    .no-js &,
    &.uber-accordion__target-active {
      padding: 1rem;
    }
  }
  &__list {
    @include sq-list-reset();
  }
  &__category {
    margin-bottom: 0.25rem;
    justify-content: space-between;
    width: 100%;
    @include sq-flex-box();
  }
  &__category-name {
    line-height: 1.2;
    margin-top: 3px;
  }
  &__more-button {
    margin-top: 1rem;
  }
  &__wrapper .facet__header {
    margin-top: 0;
    line-height: 1.2;
  }
  a:not(.facet__label) {
    + .facet__body,
    + .facet__body.uber-accordion__target-active {
      padding: 0;
    }
  }
  @include mq-tablet() {
    max-width: 24rem;
    .facet__label {
      padding: 1rem;
      pointer-events: none;
      &:after {
        display: none !important;
      }
    }
    .facet__body {
      display: block;
      max-height: 100em !important;
      padding: 1rem;
      &.uber-accordion__target-active {
        padding: 1rem;
      }
    }
  }
}

.applied-facets {
  display: inline-block;
  margin: 0 1rem 1rem 0;
  &__list,
  &__item {
    display: inline-block;
  }
  &__list {
    @include sq-list-reset();
  }
  .applied-facets__link {
    color: white;
    vertical-align: pxToRem(1);
    &:hover,
    &:active,
    &:focus {
      background-color: color(ocean);
      @include sq-box-shadow(none);
    }
  }
}
.results-list .applied-facets__clear-all.button {
    display: block;
    margin-bottom: 1rem;
    max-width: 10rem;
}


.results-list {
  &__image-wrapper {
    display: none;
  }
  @include mq-tablet() {
    &__image-wrapper {
      display: block;
      width: 200px;
      height: calc(100% - 2em);
      position: absolute;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      + .results-list__content-wrapper {
        width: calc(100% - 220px);
        margin-left: 220px;
      }
    }
  }
}

.compat-object-fit {
  background-position: center center;
  background-size: cover;

  img {
    opacity: 0;
  }
}