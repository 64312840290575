/* Module: S */
.content-wrapper .list-resources,
.list-resources {

    // padding-top: pxToRem(35);

    &__item {

        margin: 0;
        padding: pxToRem(38) 0 pxToRem(30) 0;
        border-bottom: pxToRem(1) solid $very-light-pink-four;

        &-title,
        &-title a {
            
            font-size: pxToRem(24);
            line-height: pxToRem(30);
        }

        &-type {
            margin: 0 0 pxToRem(12) 0;
        }

        &-title {
            margin: 0 0 pxToRem(10) 0;
        }

        &-content {
            color: $dark-grey;
        }

    }
    
}