/* Module: S */
.hero-slider {

    height: pxToRem(540);

    &__top {
        height: pxToRem(540);
    }

    &__wrapper {
        height: pxToRem(540);
    }

    &__item {
        height: pxToRem(540);
        display: flex !important;
        justify-content: center;
        align-content: stretch;
        align-items: stretch;
        width: 100%;

        &:before {
            width: 90%;
        }

        .hero-slider__centre {
            height: 100%;
            max-width: pxToRem($max-width - 120);
        }

        &-left {
            padding: 0;
            margin: pxToRem(50) 0 0 0;
            max-width: pxToRem(500);
        }

        &-title {
            font-size: pxToRem(48);
            line-height: pxToRem(50);
            padding-bottom: pxToRem(24);
            margin-bottom: pxToRem(21);
        }

        &-content {
            margin-bottom: pxToRem(25);
        }

    }

    &__centre {
        width: 100%;
        max-width: pxToRem($max-width);
        padding: 0 pxToRem($padding);
    }

    &__nav {

        .hero-slider__centre {
            padding: 0 pxToRem($padding);
        }
    }

    &__tabs {
        display: flex;
        justify-content: center;

        .slick-list {
            padding: 0 !important;
        }

        &:before {
            display: none;
        }

        .hero-slider__centre {
            position: relative;
            overflow: hidden;
            max-width: pxToRem($max-width - 120);
            padding: 0 pxToRem($padding);

            &:after {
                bottom: 0;
            }
        }

        button {
            height: pxToRem(80);
            padding-right: pxToRem(30);
        }
    }

}