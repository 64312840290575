/* Module: S */
.intro-resource {

    margin: pxToRem(35) 0 pxToRem(46) 0;

    &__wrapper {
        background-size: pxToRem(82);
        padding: 0 0 0 pxToRem(98);
        min-height: pxToRem(82);
        background-repeat: no-repeat;
        background-size: pxToRem(82);
        background-position: left top;
        max-width: pxToRem(830);
    }

    &__title {
        background-image: none !important;
        padding: 0;
        min-height: 0;
        margin: 0 0 pxToRem(17) 0;
        font-size: pxToRem(42);
        line-height: pxToRem(45);
        max-width: 100%;
    }

    &__content {

        p {
            font-size: pxToRem(16);
            line-height: pxToRem(26);
            color: $greyish-brown;
        }
        
    }

    &__cta {
        margin: pxToRem(25) 0 0 0;
    }

}