/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of 
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
// 
// .box-list__element { ... }
// .box-list__element--modifier { ... }
// .box-list__sub-element { ... }

// 2+ columns, fixed size boxes
.box-list {
  &__item {
    display: inline-block;
    margin: 0.5rem;
    width: 18.125rem;
    height: 11.25rem;
    .box-list__link {
      height: 11.25rem;
      width: 18.125rem;
    }
  }
}