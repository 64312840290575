/* Module: S */
body.member-logged-in {
    .hero-slider {

        &__item {

            .hero-slider__centre {

                max-width: 65vw;

            }

        }

    }
}

.hero-slider {

    height: pxToRem(540);

    &__nav {
        width: 100%;
        display: flex;
        justify-content: space-between;
        bottom: 50%;
        padding: 0 pxToRem($padding * 2);
    }
    
    
    &__item {

        .hero-slider__centre {
            height: pxToRem(460);
        }

        &:before {
            width: 60%;
            background: linear-gradient(to left, rgba(238, 238, 238, 0), rgba(247, 247, 247, 0.54) 19%, $white);
        }
        
        .hero-slider__centre {
            display: flex;
            align-content: center;
            align-items: center;
        }

        &-left {
            padding: 0;
            margin: 0;
        }

    }

    &__play-pause {
        right: pxToRem(51);
        bottom: pxToRem(-115);
        position: absolute;
    }

    &__arrow {
        width: pxToRem(60);
        height: pxToRem(60);

        .icon-arrow {
            &:before {
                line-height: pxToRem(65);
            }
        }
    }

    &__tabs {

        .slick-slide div {
            padding-right: 0;
        }
    }

}