/* Module: Accordion */
// Default states for the uber accordion target
// This example is some simple show/hide behavior
// .js-enabled .uber-accordion__target {
//     display: none;
//     &.uber-accordion__target-active {
//         display: block;
//     }
// }

////////////////////////
// Accordion Examples //
////////////////////////

// Active button state examples
.accordion__item,
.tabs__item {
  margin-top: 5px;
}

.accordion__item {
  .accordion__link {
    display: block;
    background-color: color(white-three);
    padding: $base-accordion-link-padding-hori $base-accordion-link-padding-vert;
    color: #000;
    font-size: $base-accordion-link-font-size;
    line-height: 1.5;

    &::after {
      content: '+';
      color: color(light-navy);
      width: 1em;
      font-size: 1.5em;
      line-height: 1;
      text-align: center;
      float: right;

      .no-js & {
        content: '';
      }
    }

    &.uber-accordion__button-active {
      &::after {
        content: '-';
        color: color(light-navy);
      }
    }
  }
}

// CSS Animation examples for accordion target expand/collapse behavior
.js-enabled {
  .accordion__target {
    // @include sq-transition(opacity .4s ease, max-height .4s ease);
    display: none;
    // opacity: 0;
    // max-height: 0;

    &.uber-accordion__target-active {
      // Height needs to be set to something far beyond whatever the content will be
      padding-top: $base-accordion-target-spacing;
      padding-bottom: $base-accordion-target-spacing;
      // max-height: 1000rem;
      // opacity: 1;
      display: block;
    }
    font-size: $base-accordion-link-font-size;
    line-height: 1.5;
  }

  .tabs__target {
    display: none;
    padding-left: $base-accordion-link-padding-hori;
    font-size: $base-accordion-link-font-size;
    line-height: 1.5;
    padding-top: 0;
    padding-bottom: 0;

    &.uber-accordion__target-active {
      display: block;
      padding-top: $base-accordion-target-spacing;
      padding-bottom: $base-accordion-target-spacing;
    }
  }
}

///////////////////
// Tabs Examples //
///////////////////

// make the buttons look like tabs
.tabs__tab-list {
  @extend %list-reset;

  &.wide {
    display: none;
  }
}

.tabs__item {

  .tabs__link {
    display: block;
    background-color: color(white-three);
    padding: $base-accordion-link-padding-hori $base-accordion-link-padding-vert;
    color: #000;
    font-size: $base-accordion-link-font-size;
    line-height: 1.5;

    &::after {
      content: '+';
      color: color(light-navy);
      width: 1em;
      font-size: 1.5em;
      line-height: 1;
      text-align: center;
      float: right;

      .no-js & {
        content: '';
      }
    }

    &.uber-accordion__button-active {
      &::after {
        content: '';
      }
    }
  }

}

////////////////////
// No JS Fallback //
////////////////////

// No js fallback to css :target
// (you'll need to apply these styles, or add the class to the target elements)
.no-js {
  .uber-accordion__target {
    display: none;

    &:target {
      display: block;
    }
  }
}
