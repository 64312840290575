/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of 
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
// 
// .member-nav__element { ... }
// .member-nav__element--modifier { ... }
// .member-nav__sub-element { ... }
.off-canvas {
  position: static;
  &__content {
    position: static;
    .active-mobile-member-nav & {
      left: 0;
    }
  }
}
.member-nav {
  display: none !important;
}