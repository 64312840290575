/* Module: S */
@each $name, $value in $colours {
  .bg-#{$name} {
    background-color: $value;
  }

  .text-#{$name} {
    color: $value;
  }
}
