/* Module: Matrix form */
// Global form styles
.sq-form-section,
.sq-form-unattached,
.sq-form {
  fieldset {
    border: 0;
    padding: 0;
    margin: 0;
  }

  legend {
    font-size: 1.2rem;
    font-weight: bold;
    border-bottom: 0;
  }

  input {
    margin-bottom: 1rem;

    &[type='file'] {
      min-width: 0;
      width: 100%;
      line-height: 1;
      &::-webkit-file-upload-button {
        -webkit-appearance: button;
        background-color: color(white-three);
        border: 1px solid $matrix-form__border-colour;
        font-family: inherit;
        margin: -0.35rem -0.5rem;
        padding: 0.5rem;
      }
    }

    &[type='radio'],
    &[type='checkbox'] {
      position: absolute;
      z-index: -1;
      opacity: 0;
    }

    &[type='radio'] + label,
    &[type='checkbox'] + label {
      display: inline-block;
      position: relative;
      margin: 0;
      padding-left: 1.875rem;
      font-weight: normal;
      &:before {
        content: '';
        position: absolute;
        width: 19px;
        height: 19px;
        top: 3px;
        left: 0;
        background-color: color(white-three);
        border: 1px solid $matrix-form__border-colour;
      }
      &:hover:before {
        background-color: darken(color(white-three), 5%);
      }
    }

    &[type='radio']:focus + label:before,
    &[type='radio']:active + label:before,
    &[type='checkbox']:focus + label:before,
    &[type='checkbox']:active + label:before {
      border-color: color(light-navy);
    }

    &[type='radio']:disabled + label,
    &[type='checkbox']:disabled + label {
      &:before {
        background-color: lighten(color(white-three), 10%);
        border-style: dotted;
      }
    }

    &[type='radio'] + label {
      &:before {
        @include sq-border-radius(50%);
      }
    }

    &[type='radio']:checked + label {
      &:after {
        content: '';
        position: absolute;
        width: 9px;
        height: 9px;
        top: 8px;
        left: 5px;
        background-color: color(light-navy);
        @include sq-border-radius(50%);
      }
    }

    &[type='radio']:disabled:checked + label {
      &:after {
        background-color: color(greyish-brown-three);
      }
    }

    &[type='checkbox']:checked + label {
      &:after {
        content: '';
        position: absolute;
        width: 10px;
        height: 19px;
        top: -2px;
        left: 8px;
        border: solid color(light-navy);
        border-width: 0 4px 4px 0;
        @include sq-transform(rotate(45deg));
      }
    }

    &[type='checkbox']:disabled:checked + label {
      &:after {
        border-color: color(greyish-brown-three);
      }
    }

    &[type='text'],
    &[type='email'],
    &[type='tel'],
    &[type='password'],
    &[type='file'] {
      width: 100%;
      background: white;
      color: black;
      padding: 1rem;
      border: 1px solid $matrix-form__border-colour;

      &:focus,
      &:active {
        outline: none;
        border-left: 4px solid color(light-navy);
        padding-left: pxToRem(13);
      } //:active, focus
    }
    &[type='hidden'] {
      display: none !important;
    }
  }

  select {
    line-height: 1.25rem;
  }

  textarea {
    width: 100%;
    background: white;
    color: black;
    border: 1px solid $matrix-form__border-colour;
    padding: 0.625rem 1rem;
    max-height: 150px;
    &:focus,
    &:active {
      outline: none;
      border-left: 4px solid color(light-navy);
      padding-left: pxToRem(13);
    } //:active, focus
  }

}

.sq-form-question {
  @extend %clearfix;
  margin-bottom: 1rem;
  padding: 0.5rem;
}

// Fields
.sq-form-field {
  border: 1px solid $matrix-form__border-colour;
}


// Titles & Labels
.sq-form-question-title {
  display: block;
  line-height: 1.2;
  color: color(black-two);
  font-weight: normal;
}


.sq-form-section-title {
  @include sq-font-size(1.2);
  font-weight: bold;
  border-top: 1px solid $matrix-form__border-colour;
  margin-top: 1rem;
  display: block;
  padding-top: 1rem;
  width: 100%;
  margin: 0.5rem 0;
}

// Lists
.sq-form-question-tickbox-list,
.sq-form-question-option-list {
  ul:not([class]) {
    @extend %list-reset;
    li {
      clear: left;
      display: block;
      padding: 0;
      &:before {
        display: none;
      }
    }
  }
}



// Notes
.sq-form-question-note {
  font-size: 90%;
  font-style: italic;
  display: block;
  margin-bottom: 0.5rem;
}


// Date/Time
.sq-form {
  .sq-form-question-datetime {
    input,
    select,
    label {
      font-weight: normal;
      display: block;
    }
  }
}

.sq-form-error,
.sq-form-required-field {
  margin-top: pxToRem(6);
  color: color(scarlet) !important;
  font-size: pxToRem(12);
  line-height: 1.7;
  font-weight: normal;
}

.sq-form-errors-message {
  color: color(scarlet) !important;
}

.sq-form-error {
  display: block;
  margin-top: 0;
}


.sq-form-question-error {
  input {
    &.sq-form-field {
      &[type='text'],
      &[type='email'],
      &[type='number'],
      &[type='password'],
      &[type='file'] {
        border-left: 4px solid color(scarlet);
        padding-left: pxToRem(13);
      }
    }
  }
}
