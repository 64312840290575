/* Module: Accordion */
// .accordion__link {
// 	padding: $base-accordion-link-padding-hori*2 $base-accordion-link-padding-vert*2;
// 	font-size: $base-accordion-link-font-size*1.8;
// }

// .js-enabled .accordion__target {
// 	&.uber-accordion__target-active {
// 		// Height needs to be set to something far beyond whatever the content will be
// 		padding-top: $base-accordion-target-spacing*2;
// 		padding-bottom: $base-accordion-target-spacing*2;
// 	}
// 	padding-left: $base-accordion-link-padding-hori*2;
// 	font-size: $base-accordion-link-font-size*1.8;
// }


// .tabs__link {
//   padding: $base-accordion-link-padding-hori*2 $base-accordion-link-padding-vert*2;
//   font-size: $base-accordion-link-font-size*1.8;
// }

// .js-enabled .tabs__target {
//     padding-left: $base-accordion-link-padding-hori*2;
//     font-size: $base-accordion-link-font-size*1.8;

//     &.uber-accordion__target-active {
//         padding-top: $base-accordion-target-spacing*2;
//         padding-bottom: $base-accordion-target-spacing*2;
//     }
// }