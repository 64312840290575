/* Module: S */
.related-cards {

    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-content: stretch;
        align-items: stretch;
        flex-flow: row wrap;
    }

    &__item {
        width: 48.75%;
        padding: pxToRem(32) pxToRem(30) pxToRem(35) pxToRem(30);

        > a {
            display: flex;
            align-content: flex-start;
            justify-content: flex-start;
            flex-flow: row nowrap;
        }

        &-title {
            margin: 0 0 pxToRem(10) 0;
        }

    }

    &__right {
        margin-left: pxToRem(27);
    }

}