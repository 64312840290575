/* Module: S */
.content-container .block-icons,
.block-icons {

    &__main-title {
        @include font-smoothing;
        color: $black;
        font-size: pxToRem(27);
        line-height: pxToRem(32);
        font-weight: 600;
        margin: 0 0 pxToRem(40) 0;
    }

    &__item {
        
        margin: 0 0 pxToRem(53) 0;

        &-icon {
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            width: pxToRem(82);
            height: pxToRem(82);
            margin: 0 0 pxToRem(13) 0;
        }

        &-title {
            @include font-smoothing;
            font-size: pxToRem(18);
            line-height: pxToRem(23);
            color: $black;
            font-weight: 600;
            margin: 0 0 pxToRem(15) 0;
        }

        &-content {
            font-size: pxToRem(14);
            line-height: pxToRem(24);
            color: $dark-grey;
            font-weight: 400;
            margin: 0 0 pxToRem(17) 0;
        }
    }

    &__buttons {
        
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-content: center;
        align-items: center;

        a {
            @include font-smoothing;
            @include sq-box-shadow(inset 0 0 0 0 transparent);
            @include sq-transition(all 0.2s);
            @include sq-border-radius(999px);
            display: block;
            width: auto;
            font-size: pxToRem(14);
            line-height: pxToRem(15);
            font-weight: 500;
            margin: 0 pxToRem(10) 0 0;
            text-transform: uppercase;
            padding: pxToRem(16) pxToRem(15);
            background-color: $macaroni-and-cheese;
            color: $greyish-brown;
            border: none;

            &:hover,
            &:focus {
                @include sq-box-shadow(inset 0 80px 0 0 $ocean);
                color: $white;
            }

            &:last-child {
                margin-right: 0;
                background-color: $black-two;
                color: $white;
            }
        }
    }   
}

@media (min-width: 107.500em) {

    body.member-logged-in {

        .content-container .block-icons,
        .block-icons {
            &__item {
                &-content {
                    margin: 0;
                    padding: 0 0 pxToRem(90) 0 !important;
                }
            }
        }
    }
}