/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
//
// .mainnav__element { ... }
// .mainnav__element--modifier { ... }
// .mainnav__sub-element { ... }

.mainnav {
  display: none;
}

// max-width 1px less than tablet
@media screen and (max-width: 999px) {
  .search {
    display: none !important;
  }
}

@include mq-tablet() {
  .mainnav {
    display: block;
    position: relative;
    margin: pxToRem(26) 3.5% 0;
    &__logo {
      float:left;
      vertical-align: middle;
      a{
        img{
          width: 240px;
        }
      }
    }
    &__nav {
      clear: both;
      padding-top: 1rem;
    }
    &__list {
      @include sq-flex-box();
      @include sq-list-reset();
      align-items: center;
      border-top: 1px solid color(white-two);
      justify-content: space-between;
      align-content: stretch;
      align-items: stretch;
      margin-bottom: pxToRem(2);
      flex-flow: row nowrap;

      li {
        list-style: none;
        flex-direction: column;
        justify-content: center;
        @include sq-flex-box();
        align-content: stretch;
        align-items: stretch;
        width: 100%;
        text-align: center;
        justify-content: center;

        .no-js &,
        .no-flexbox & {
          display: table-cell;
          vertical-align: middle;
        }
        a {
          text-decoration: none;
          font-family: Cabin;
          font-weight: 500;
          color: color(greyish-brown-three);
          text-transform: uppercase;
          display: block;
          padding: pxToRem(23) pxToRem(20);
          text-align: center;
          display: flex;
          align-content: center;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 100%;
          @include sq-transition(background-color 0.3s ease);
          
          &:hover,
          &:focus,
          &:active {
            text-decoration: none;
            background-color: $ocean;
            color: $white;
          }
        }
        &.active a {
          background-color: $ocean;
          color: $white;
        }
      }
    }
    &__right {
      float:right;
      vertical-align: middle;
    }
    &__sign-in,
    &__search{
      float: right;
    }
    &__sign-in i {
      line-height: 1;
      margin-left: 0.5rem;
      vertical-align: middle;
    }
  }
  // Search
  .search {
      margin-right: .75rem;
    &__input-wrapper {
      border-color: $matrix-form__border-colour;
      line-height: 51px;
      position: relative;
    }
    &__button {
      background: none;
      border: none;
      color: color(light-navy);
      height: 50px;
      padding: 0 0 0 1rem;
      text-align: right;
      vertical-align: middle;
      @include sq-transition(transform 0.2s);
      &:hover,
      &:active,
      &:focus {
        @include sq-transform(scale(1.25));
      }
      i::before {
        float: right;
        font-size: 1.25rem;
        line-height: 0.8;
      }
    }
    .search__input {
      @include sq-transition(width 0.2s);
      @include placeholder() {
        color: color(greyish-brown-three);
      }
      height: 50px;
      width: 59px;
      &:focus {
        color: black;
        width: 30vw;
      }
    }
  }
  // Sign-in Popup
  .sign-in-popup {
    display: none;
    position: absolute;
    right: 0;
    width: 19rem;
    height: 16rem;
    z-index: 1100;
    text-align: right;
    &__arrow {
      display: inline-block;
      margin-right: 50px;
      vertical-align: bottom;
      @include sq-arrow(top, color(light-navy), 11px);
    }
    &__box {
      background-color: color(light-navy);
      font-size: 0.875rem;
      padding: 2.875rem 2.625rem 2rem;
      text-align: center;
    }
    &__form-field {
      margin-bottom: 2.5rem;
      i {
        color: black;
        margin-right: 1.5rem;
        &::before {
          @include sq-transform(unquote("scale(2) translate(3px, 1px)"));
        }
      }
    }
    &__input-wrapper {
      background-color: white;
      color: black;
      border-color: transparent;
      input {
        @include placeholder() {
          color: black;
        }
      }
    }
    &__link {
      color: white;
      text-decoration: none;
      &:hover,
      &:active,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}
