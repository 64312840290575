/* Module: S */
.hero-slider {

    position: relative;
    clear: both;
    height: pxToRem(470);
    
    &__top {
        height: pxToRem(470);
        width: 100%;
        position: relative;
        float: left;
    }

    &__wrapper {
        height: pxToRem(470);
        width: 100%;
    }

    &__item {
        height: pxToRem(470);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
        position: relative;
        display: flex;
        align-content: stretch;
        align-items: stretch;

        &:before {
            background: linear-gradient(to left, rgba(238, 238, 238, 0), rgba(247, 247, 247, 0.54) 19%, $white);
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
        }

        &-left {
            position: relative;
            z-index: 1;
            padding: 0 pxToRem($padding);
            max-width: 95%;
            width: 100%;
            margin: pxToRem(30) 0 0 0;
        }

        &-title {
            @include font-smoothing;
            font-size: pxToRem(26);
            line-height: pxToRem(30);
            color: $black;
            font-weight: 600;
            text-transform: uppercase;
            margin: 0 0 pxToRem(18) 0;
            padding: 0 0 pxToRem(18) 0;
            position: relative;

            &:after {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                max-width: pxToRem(143);
                height: pxToRem(1);
                background-color: $turquoise-blue;
            }
        }

        &-content {
            color: $greyish-brown;
            font-size: pxToRem(16);
            line-height: pxToRem(27);
            font-weight: 400;
            margin: 0 0 pxToRem(20) 0;
        }

    }

    &__nav {

        padding: 0 pxToRem($padding);
        width: 100%;
        position: absolute;
        left: 0;
        bottom: pxToRem(100);
        z-index: 1;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: space-between;

    }

    &__play-pause {
        @include clear-default-appearance;
        @include sq-border-radius(999px);
        @include sq-transition(background-color 0.4s ease);
        display: block;
        width: pxToRem(38);
        height: pxToRem(38);
        background-color: $purple-brown;
        border: none;
        position: relative;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        position: relative;

        &.active {

            &:before {
                width: 0;
                height: 0;
                border-style: solid;
                border-width: pxToRem(8) 0 pxToRem(8) pxToRem(14);
                border-color: transparent transparent transparent $white;
                background: none;
                margin: 0 pxToRem(-3) 0 0;
            }

            &:after {
                display: none;
            }
        }

        &:before {
            content: "";
            background-image: url('../mysource_files/icon-pause.png');
            background-size: pxToRem(11);
            background-repeat: no-repeat;
            background-position: center;
            height: pxToRem(16);
            width: pxToRem(11);
            position: absolute;
            left: pxToRem(13);
            top: pxToRem(11);
        }

        &:hover,
        &:focus {
            background-color: $ocean;
            outline: 0;
        }
    }

    &__arrow {
        @include clear-default-appearance;
        @include sq-border-radius(999px);
        @include sq-transition(background-color 0.4s ease);
        background-image: url('../mysource_files/icon-arrow-left.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: pxToRem(20);
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        width: pxToRem(42);
        height: pxToRem(42);
        background-color: $purple-brown;
        border: none;
        padding: 0;

        &:hover,
        &:focus {
            background-color: $ocean;
            outline: 0;
        }

        &--next {
            background-image: url('../mysource_files/icon-arrow-right.png');
        }

        .icon-arrow {
            color: $white;
            height: pxToRem(42);
            display: flex;
            align-content: center;
            align-items: center;

            &:before {
                margin: 0;
                vertical-align: middle;
                margin: 0;
            }
        }
    }

    &__tabs {
        width: 100%;
        background-color: rgba($purple-brown, 0.9);
        float: left;
        position: absolute;
        bottom: 0;
        z-index: 5;
        width: 100%;
        
        .hero-slider__centre {
            position: relative;
            width: 100%;
            height: 100%;
            padding: 0 0 pxToRem(16) 0;
        }


        .slick-list {
            padding: 0 20px !important;
            position: relative;
            z-index: 5;
            overflow: hidden;

            &:after {
                content: "";
                width: 100%;
                height: pxToRem(2);
                background-color: $butterscotch;
                position: absolute;
                bottom: 0;
                z-index: 0;
            }
        }

        .slick-track {
            z-index: 20;
        }

        .slick-slide.slick-current.slick-active,
        .slick-slide.slick-current {
            button {
                border-bottom: pxToRem(6) solid $turquoise-blue;
                color: $white-two;
                opacity: 1;
            }
        }

        .slick-slide {

            &:focus {
                
                outline: 0;

                button {
                    text-align: underline;
                }
            }

            div {
                padding-right: pxToRem(30);
            }
        }

        button {
            @include sq-transition(all 0.4s ease);
            @include clear-default-appearance;
            @include font-smoothing;
            border: 0;
            background: none;
            color: $very-light-pink;
            font-size: pxToRem(14);
            line-height: pxToRem(17);
            font-weight: 600;
            opacity: 0.8;
            text-transform: uppercase;
            text-align: left;
            padding: pxToRem(23) 0 pxToRem(18) 0;
            cursor: pointer;
            width: auto !important;
            border-bottom: pxToRem(6) solid transparent;

            &:hover,
            &:focus {
                outline: 0;
                opacity: 1;
            }

            &:focus {
                text-decoration: underline;
            }
        }
    }


}