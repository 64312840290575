/* Module: S */
.article-pagination {
    margin-top: 3rem;
    &__prev {
        float: left;
    }
    &__next {
        float: right;
    }
    &__arrow {
        vertical-align: 2px;
    }
    &__text {
        font-weight: 500;
    }
}
