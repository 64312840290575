/* Module: S */
.modal__wrap {
  top: 10%;
  width: 80%;
  max-height: 80%;
  transform: translate(-50%, 0);
  overflow-y: auto;
  
  .btn-row {
    flex-direction: row;

    .button {
      & + .button {
        margin-left: 1.5rem;
      }
    }
  }
}
