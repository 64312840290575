/* Module: S */
.stripe {
    padding: pxToRem(74) pxToRem(20);
    @include mq-tablet() {
        padding-right: pxToRem(36);
    }
    p {
        font-size: pxToRem(18);
        color: color('greyish-brown-three');
    }
    h2 {
        font-size: pxToRem(42);
        margin-bottom: pxToRem(24);
        +.line {
            height: 2px;
            +* {
                margin-top: pxToRem(22);
            }
        }
    }
    .content-container & {
        padding: pxToRem(74) 0;
    }
}

.stripe__inner {
    max-width: pxToRem(1120);
    margin: 0 auto;
}

.stripe__title {
    text-align: center;
}

.action {
    margin-top: pxToRem(30);
    text-align: center;
    .button,
    .button+.button {
        margin: 0 pxToRem(10) pxToRem(10);
        .content-container & {
            margin-left: pxToRem(10);
        }
    }
}

.stripe--text {
    text-align: center;
    background: color('white-two');
    a {
        color: color('light-navy');
    }
}

.stripe--listing {
    color: color('white');
    background: color('ocean');
    h2 {
        color: #fff;
    }
    p {
        padding: 0 10%;
        text-align: center;
        color: color('white');
    }
}

.link-list {
    margin: 0;
    padding: pxToRem(10) pxToRem(20);
    list-style: none;
}

.link-list__item {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    text-align: center;
}

a.link-list__link {
    font-size: pxToRem(16);
    font-weight: bold;
    line-height: pxToRem(40);
    text-transform: uppercase;
    color: #fff;
}

.asset-snippets__list {
    display: block;
    width: 100%;
}

.asset-snippets__title {
    font-size: pxToRem(16);
    font-weight: 500;
    line-height: 1.1em;
    margin-bottom: pxToRem(10);
}

.asset-snippets__link {
    color: color('black');
}

.asset-snippets__desc {
    display: none;
    margin-top: 30px;
    .more {
        font-size: pxToRem(14);
        display: block;
        margin-top: pxToRem(16);
        padding-right: pxToRem(10);
        text-decoration: none;
        color: color(ocean);
        &:hover {
            text-decoration: underline;
        }
    }
}

.stripe--rich-snippets {
    background: color('white-two');
}

.asset-snippets__list--rich {
    padding-top: pxToRem(40);
}

.asset-snippets__item--rich {
    padding-bottom: pxToRem(20);
    width: auto;
    .asset-snippets__desc {
        display: block;
    }
}