
/*
--------------------
Modules
--------------------
*/

.section {

    flex-flow: row nowrap;
    align-items: stretch;
    align-content: stretch;

    &__centre {
        width: 100%;
        max-width: pxToRem(1130);
    }

    &__inner {
        
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: flex-start;
    }

}

/*
---------------------------------
OLD CSS styles for NEWS and Latest BLOG sections
---------------------------------
*/

.latest-news {
    h3 {
        font-size: 30px;
        margin: 0 auto 37px;
    }

    .news-cols {
        margin-top: 46px;

        .news-col {

            .news-item {
                width: 487px;
                margin: 0 auto 50px;

                h4 {
                    font-size: 18px;
                    margin-bottom: 20px;
                    color: $greyish-brown-four;

                    a {
                        color: $greyish-brown-four;
                    }
                }

                .date {
                    font-size: 14px;
                    margin-top: 10px;
                }

            }

        }
    }

    //news-cols

    .action {
        .button {
            font-size: 14px;
        }
    }
}

.latest-news,
.latest-events,
.latest-training {
    padding-top: 62px;
}
