/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
//
// .feedback__element { ... }
// .feedback__element--modifier { ... }
// .feedback__sub-element { ... }

.no-js .feedback {
  display: none;
}

.feedback {
  display: block;
  position: relative;

  &__form-wrapper {
    background-color: color(light-navy);
    color: #fff;
    padding: 2rem 0;
    @include mq-tablet() {
      display: flex;
      height: 350px;
      margin: 0;
      padding: 3rem 10rem;
      position: absolute;
      text-align: left;
      top: 0;
      width: 100%;
      z-index: 10;
    }
  }

  &__form {
    display: block;
    margin: 0 auto;
    max-width: 84.375%;
    @include mq-tablet() {
      display: flex;
      max-width: none;
      width: 100%;
      .left {
        flex: 1;
        flex-basis: 78%;
        padding-right: 2rem;
      }

      .right {
        flex: 1;
        flex-basis: 22%;
        padding-top: 3rem;
      }
      .sq-form-control {
        margin-top: 1rem;
      }
    }
    p {
      font-size: 16px;
      padding: .5rem;
    }

    .sq-form-error {
      line-height: 1.2;
      padding: 0;
      margin: 0;
    }

    .feedback-close {
      position: absolute;
      top: 1.5rem;
      right: 1.5rem;
      color: #fff;
    }

    .sq-form-question-title {
      color: #fff;
      font-weight: normal;
      font-size: 16px;
    }

    .sq-form-question {
      margin-bottom: 0;
    }

    .row {
      display: block;
      @include mq-tablet() {
        display: flex;
      }

      .sq-form-question {
        @include mq-tablet() {
          flex: 1;
          padding-right: 2rem;
        }

        input,
        textarea {
          width: 100%;
          height: 45px;
          padding: 0 20px;
          line-height: 45px;
          color: #000;

          &:focus {
            border-left: 5px solid color(ocean);
          }
        }

      }
    }

    .sq-form-question-option-list {

      legend {
        border-bottom: 0;
        margin-bottom: 0;
      }

      .sq-form-question-answer {
        ul {
          li {
            display: inline-block;
            margin-right: 20px;

            input {
              margin-right: 5px;
            }
            label {
              padding-left: 1.6rem;
            }
          }
        }
      }
    }

  }

  .feedback__toggle {
    display: none;
    position: absolute;
    top: 0;
    left: 42px;
    z-index: 10;
    width: 90px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
    background-color: color(light-navy);
    text-decoration: none;
    font-size: 12px;
    color: #fff;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }

    @include mq-tablet() {
      display: block;
    }

  }

}
