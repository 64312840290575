/* Module: Back to top */
.back-to-top {
  text-align: center;
  display: block;
  text-decoration: none;
  position: fixed;
  bottom: 4px;
  right: 4px;
  width: 48px;
  height: 48px;
  line-height: 48px;
  font-size: 24px;
  background-color: darken(color(ocean) , 20%);
  color: #fff;
  z-index: 1001;
  transition: opacity .2s ease-out;
  opacity: 0;
  @include sq-border-radius(50%);

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &.show {
    opacity: .8;
  }

}
