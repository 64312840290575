/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
//
// .icon-tiles__element { ... }
// .icon-tiles__element--modifier { ... }
// .icon-tiles__sub-element { ... }

.icon-tiles {
  @extend %clearfix;
  background-color: color(white-two);
  text-align: left;
  padding: 25px 0;

  &__heading {
    text-transform: uppercase;
  }

  .icon-tiles__item {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0 30px;

    a {
      color: #000;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .icon-wrapper {
      text-align: center;
      flex-basis: 32.8%;
      // height: 40px;
      // line-height: 40px;
      padding-right: 25px;

      img {
        width: 40px;
      }
    }

    .text-wrapper {
      padding-left: 25px;
      border-left: 1px solid color(ocean);
      flex-basis: 67.2%;
    }

    h3 {
      font-size: 15px;
      font-weight: bold;
      color: #000;
      margin-top: 0;
      margin-bottom: 6px;
    }

    p {
      margin: 0;
      font-size: 13px;
      line-height: 1.4;
      color: color(greyish-brown-three);
    }
  }

  // regional
  &.regional {
    background-image: none;
  }
}
