/* Module: Responsive tables */
.responsive-table {
  caption {
    caption-side: bottom;
  }
  col:first-child {
    border-right: 1px solid color(black-two);
  }
  thead {
    position: inherit;
  }
  tbody {
    display: table-row-group;
  }
  th {
    display: table-cell;
    background-color: transparent;
    color: color(greyish-brown-three);
    border-bottom: 1px solid color(black-two);
    text-align: left;
  }
  tr {
    display: table-row;
    border: none;
    &:hover td,
    &:hover th {
      background-color: white;
    }
  }
  td,
  th[scope="row"] {
    display: table-cell;
    text-align: left;
    &:before,
    &:empty:after {
      display: none;
    }
    &:last-child {
      border-bottom: 1px solid color(pinkish-grey);
    }
  }
}

.responsive-table__wrapper {
  overflow-x: scroll;
}