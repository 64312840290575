/* Module: S */
.filter-bar {

    margin: 0 0 pxToRem(40) 0;

    &__title {
        font-size: pxToRem(15);
        line-height: pxToRem(30);
        color: $dark-grey;
        font-weight: 400;
        margin: 0 0 pxToRem(12) 0;
        text-transform: uppercase;
    }

    &__current-selected {

        width: 100%;
        margin: 0 0 pxToRem(3) 0;

        button {
            @include clear-default-appearance;
            @include sq-border-radius(999px);
            @include sq-transition(background-color 0.4s ease);
            padding: 0;
            border: none;
            background-color: $macaroni-and-cheese;
            font-size: pxToRem(18);
            line-height: pxToRem(18);
            font-weight: 500;
            width: 100%;
            padding: pxToRem(14) pxToRem(53) pxToRem(14) pxToRem(23);
            text-align: left;
            position: relative;
            cursor: pointer;

            &:before,
            &:after {
                content: "";
                @include sq-transition(all 0.4s ease);
                position: absolute;
                right: pxToRem(17);
                top: pxToRem(23);
                width: pxToRem(11);
                height: pxToRem(1);
                background-color: $black-three;
            }

            &:before {
                @include sq-transform(rotate(-45deg));
            }

            &:after {
                @include sq-transform(rotate(45deg));
                right: pxToRem(25);
            }

            &.active {
                &:before {
                    @include sq-transform(rotate(45deg));
                }
    
                &:after {
                    @include sq-transform(rotate(-45deg));
                }
            }

            &:hover,
            &:focus {
                background-color: $ocean;
                color: $white;

                &:before,
                &:after {
                    background-color: $white;
                }
            }


        }
    }

    &__items {
        @include sq-list-reset;
        display: none;

        li {
            @include sq-list-reset;
            border-bottom: pxToRem(2) solid $white;
            font-weight: 500;
            font-size: pxToRem(18);
            line-height: pxToRem(21);
            color: $white;

            &:last-child {
                border-bottom: none;
            }

            &.active {
                a {
                    background-color: $ocean;
                }
            }

            &.disabled {
                background-color: $brown-grey;
                padding: pxToRem(15) pxToRem(23);
            }

            a {
                @include font-smoothing;
                display: block;
                width: 100%;
                background-color: $dark-grey;
                color: $white;
                padding: pxToRem(15) pxToRem(23);

                &:hover,
                &:focus {
                    @include sq-box-shadow(none);
                    text-decoration: underline;
                }
            }

            &:before {
                display: none;
            }
        }
    }

}