/* Module: S */
.search-large {

    margin: pxToRem(45) 0 pxToRem(50) 0;

    &__title {
        font-size: pxToRem(15);
        line-height: pxToRem(30);
        color: $dark-grey;
        font-weight: 400;
        text-transform: uppercase;
        margin: 0 0 pxToRem(13) 0;
    }


    .input-group {
        @include sq-border-radius(999px);
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-content: stretch;
        align-items: stretch;
        border: 1px solid $very-light-pink-four;
    }

    .input-wrapper.round-left {
        width: 100%;
        display: flex;
        align-content: stretch;
        align-items: stretch;
        border: none;

        .fa {
            display: none;
        }
    }

    input[type="text"] {
        width: 100%;
        max-width: 100%;
        color: $black;
        font-size: pxToRem(16);
        min-height: pxToRem(30);

        &::-webkit-input-placeholder { 
            color: $black;
        }

        &::-moz-placeholder { 
            color: $black;
        }

        &:-ms-input-placeholder { 
            color: $black;
        }

        &:-moz-placeholder { 
            color: $black;
        }

        &::-ms-clear {
            display: none;
        }
    }

    input[type="submit"],
    button[type="submit"] {
        @include clear-default-appearance;
        @include sq-border-radius(0 999px 999px 0);
        border: none;
        background: none;
        padding: 0;
        width: pxToRem(60);
        min-width: pxToRem(60);
        height: pxToRem(60);
        background-color: $macaroni-and-cheese;
        @include sq-box-shadow(inset 0 0 0 0 transparent);
        @include sq-transition(all 0.2s);

        &:hover,
        &:focus {
            @include sq-box-shadow(inset 0 80px 0 0 $ocean);
            color: $white;
        }

        span {
            display: none;
        }
    }

}