/* Module: S */
.related-cards {

    &__title {
        color: $dark-grey;
        font-size: pxToRem(27);
        line-height: pxToRem(32);
        font-weight: 600;
        margin: 0 0 pxToRem(45) 0;
    }

    &__wrapper {
        width: 100%;
    }

    &__item {

        width: 100%;
        background: $white;
        margin: 0 0 pxToRem(20) 0;
        padding: pxToRem(30) pxToRem(17) pxToRem(35) pxToRem(17);

        a {
            @media screen and (min-width: 768px){
                display: flex;
                align-content: flex-start;
                justify-content: flex-start;
                flex-flow: row nowrap;
            }

            &:hover,
            &:focus {
                @include sq-box-shadow(none);
            }
        }

        &-icon {
            min-width: pxToRem(82);
            width: pxToRem(82);
            height: pxToRem(82);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            margin: 0 0 pxToRem(8) 0;
        }

        &-title {
            color: $black;
            font-size: pxToRem(24);
            line-height: pxToRem(30);
            font-weight: 600;
            margin: 0 0 pxToRem(13) 0;
        }

        &-content {
            font-size: pxToRem(16);
            line-height: pxToRem(26);
            font-weight: 400;
            color: $greyish-brown-four;
        }

    }


    &__right {

        @media screen and (min-width: 768px){
            margin-left: pxToRem(27);
        }
    }


}