/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
//
// .reusable-stripes__element { ... }
// .reusable-stripes__element--modifier { ... }
// .reusable-stripes__sub-element { ... }
.stripe {
  margin: 0;
  padding: pxToRem(74) pxToRem(20);
  .content-container & {
    padding: pxToRem(74) 0;
  }
}
.asset-snippets__list {
  max-width: pxToRem(1200);
}
.asset-snippets__item {
  display: inline-block;

  width: 32.5%;
  padding: 0 4%;

  vertical-align: top;
}
.asset-snippets__title {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.1;

  margin-bottom: 20px;

  color: color(greyish-brown-two);
}
.asset-snippets__desc {
  display: block;

  margin-top: 30px;

  color: color('greyish-brown-three');
}
.asset-snippets__list--rich {
    max-width: pxToRem(1200);
    display: flex;
}
.asset-snippets__item--rich {
  display: flex;
  padding: 0 pxToRem(45);
  width: auto;
  margin: auto;
  img {
    min-width: 240px;
    height: auto;
  }
  .asset-snippets__title {
    margin-top: 0;
  }
}
.asset-snippets__content {
  padding-left: pxToRem(30);
}
.link-list {
  padding: pxToRem(40) 10%;
}
.link-list__item {
  width: 49%;
  padding: 0 pxToRem(50);

  text-align: left;
}
