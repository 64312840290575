
/*
--------------------
Modules
--------------------
*/



/*
---------------------------------
OLD CSS styles for NEWS and Latest BLOG sections
---------------------------------
*/

.latest-news {
    clear: both;
    width: 100%;
    background-color: color(white-two);
    background-image: url('{{file_dest}}/latest-news.png');
    background-repeat: no-repeat;
    background-position: -120px -80px;

    h3 {
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        color: black;
        margin: 0 auto 20px;
    }

    .news-cols {
        margin-top: 50px;
        display: block;
        width: 100%;

        .news-col {
            display: inline-block;
            width: 33%;
            vertical-align: top;

            .news-item {
                width: 296px;
                margin: 0 auto;

                h4 {
                    font-size: 20px;
                    font-weight: bold;
                    line-height: 1.1;
                    color: $greyish-brown-four;
                    margin-bottom: 20px;

                    a {
                        color: $greyish-brown-four;
                    }
                }

                .date {
                    margin-top: 20px;
                    font-size: 14px;
                    line-height: 1.6;
                    color: color(greyish-brown-two);
                }

                .description {
                    display: block;
                    margin-top: 30px;

                    .more {
                        padding-left: 10px;
                        padding-right: 10px;
                        text-decoration: none;
                        font-size: 20px;
                        vertical-align: text-top;
                        color: color(ocean);

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

            }

        }

        //.news-col
    }

    // .news-cols

    .action {
        clear: both;
        margin-top: 60px;
        text-align: center;

        .button {
            font-size: 14px;
        }
    }

}

.latest-news,
.latest-events,
.latest-training {
    padding-top: 68px;
}

.off-canvas--active+.off-canvas__content .latest-news {
    background-image: none;
}
