/* Module: Secondary nav */
.nav {
  background-color: white;
  display: none;
  position: relative;

  &--no-height {
    width: 100%;
  }
  
  @include mq-tablet() {
    display: block;
    padding: 2rem 2rem 99999rem 0;
    position: absolute;
    width: $nav__width--tablet;

    &--no-height {
      position: relative;
    }

  }

  &__list,
  &__sub,
  &__deep,
  &__deeper {
    @extend %list-reset;
  }

  &__item,
  &__sub-item,
  &__deep-item,
  &__deeper-item {
    display: block;
    margin: 1rem 0;
    &--current:after,
    &--current li:first-child:before {
      content: "";
      display: block;
      position: relative;
      left: 0;
      width: 100%;
      height: 1px;
      margin: 1.5rem 0;
      background-color: color(white-three);
    }
    &--active:last-child:after,
    &--current:last-child:after {
      display: none;
    }
  }
  &__item,
  &__sub-item,
  &__deep-item {
    &--active:after,
    &--active li:first-child:before {
      content: "";
      display: block;
      position: relative;
      left: 0;
      width: 100%;
      height: 1px;
      margin: 1.5rem 0;
      background-color: color(white-three);
    }
  }
  &__item-link,
  &__sub-item-link,
  &__deep-item-link,
  &__deeper-item-link {
    display: block;
    color: black;
    border-bottom: none;
    border-left: 5px solid transparent;
    padding: 0 1rem;
    @extend %border-box;
    &:hover,
    &:active,
    &:focus {
      @include sq-box-shadow(none);
    }
  }

  &__item-link {
    .nav__item--active &,
    .nav__item--current &,
    .nav__item--current-nounderline &,
    &:hover,
    &:active,
    &:focus {
      border-left: 5px solid color(light-navy);
    }
  }

  &__sub-item-link {
    margin-left: $nav__indent;
    .nav__sub-item--active &,
    .nav__sub-item--current &,
    .nav__item--current-nounderline &,
    &:hover,
    &:active,
    &:focus {
      border-left: 5px solid color(white-three);
    }
  }

  &__deep-item-link {
    margin-left: $nav__indent * 2;
    .nav__deep-item--active &,
    .nav__deep-item--current &,
    .nav__item--current-nounderline &,
    &:hover,
    &:active,
    &:focus {
      border-left: 5px solid color(butterscotch);
    }
  }
  &__deeper-item-link {
    margin-left: $nav__indent * 3;
    .nav__deeper-item--active &,
    .nav__deeper-item--current &,
    .nav__item--current-nounderline &,
    &:hover,
    &:active,
    &:focus {
      border-left: 5px solid color(ocean);
    }
  }
}
