/* Module: S */
.event-blocks {
    
    &__type {
        color: $light-navy;
        font-size: pxToRem(14);
        line-height: pxToRem(23);
        font-weight: normal;
        margin: 0 0 pxToRem(10) 0;
    }

    &__header {
        display: flex;
        flex-flow: row wrap;
        align-content: center;
        align-items: center;
        margin: 0 0 pxToRem(26) 0;
    }

    &__date {
        background-color: $ocean;
        text-align: center;
        color: $white;
        width: pxToRem(51);
        height: pxToRem(51);
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        flex-flow: row wrap;
        font-size: pxToRem(14);
        line-height: pxToRem(16);
        text-transform: uppercase;

        span {
            display: inline-block;
            width: 100%;
        }
    }

    &__title {
        @include font-smoothing;
        color: $greyish-brown-four;
        font-size: pxToRem(20);
        line-height: pxToRem(23);
        margin: 0 0 0 pxToRem(17);
        font-weight: 600;
        max-width: pxToRem(210);
        width: 100%;
        a {
            color: $greyish-brown-four;
        
        }
    }

    &__content {
        font-weight: 400;
        font-size: pxToRem(16);
        line-height: pxToRem(23);
        color: $greyish-brown-four;
        margin-bottom: pxToRem(20);
    }

    &__cta {

        a {
            color: $ocean;
            @include sq-transition(padding 0.4s ease);

            &:hover,
            &:focus {
                padding-left: pxToRem(15);
            }
        }

        .icon-arrow {
            &:before {
                margin: 0;
            }
        }
    }
    
}