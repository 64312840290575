/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
//
// .hero__element { ... }
// .hero__element--modifier { ... }
// .hero__sub-element { ... }

.hero {
  .hero__bg{
    &.top-right{
      top: 0;
      right: 0;
      width: 200px;
      height: 330px;
      background-image: url("{{file_dest}}/hero-top-right.png");
      background-position: 15px 10px;
      background-size: 350px;
    }
  }
  &__content{
    h1 {
      font-size: 36px;
      margin-top: 55px;
      padding : 0 80px;
    }
    p {
      font-size: 16px;
    }
    .benefits{
      font-weight: 500;
      margin: 36px auto;
      .benefits__item{
        vertical-align: top;
        .icon-checkmark:before {
          vertical-align: -0.4rem;
        }
      }
    }
  }
}
