// Base font
$base-font-scale: 1;  // The font scale multiplier (e.g. 2 = 32px/2em/2rem)
$base-font-pixel: 16; // Used for font size calculations & conversions
$base-font-colour: #222;

$white:             #ffffff;
$white-two:         #fdfdfd;
$black:             #000000;
$black-two:         #312d2b;
$black-three:       #212121;

$light-navy:        #143980;
$very-light-pink:   #efefef;
$ocean:             #008390;
$greyish-brown-four:#505050;
$butterscotch:      #ffc937;
$greyish-brown:     #575757;
$greyish-brown-three: #595856;
$turquoise-blue:    #00a8b9;
$purple-brown:      #231f20;
$nice-blue:         #1b7ab6;
$dark-slate-blue:   #1e356b;
$dark-grey:         #363636;
$macaroni-and-cheese:  #fec325;
$very-light-pink-four: #bbbbbb;
$very-light-pink-five: #dfdfdf;
$very-light-pink-six:  #bfbfbf;
$brown-grey:           #949494;

$padding: 20;
$max-width: 1200;
$desktop-xlarge: 1720px;

// Selection highlighting
$content-selection-highlight: #b3d4fc;