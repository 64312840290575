/* Module: S */
.pagination-results {

    width: 100%;
    margin: 0 0 pxToRem(60) 0;

    &__count {
        font-size: pxToRem(15);
        line-height: pxToRem(30);
        text-align: center;
        margin: 0 0 pxToRem(15) 0;
        text-align: center;
        width: 100%;
    }

    &__pages {
        
        @include sq-list-reset;
        width: 100%;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;


        li {
            @include sq-list-reset;
            font-size: pxToRem(15);
            line-height: pxToRem(17);
            color: $greyish-brown-three;
            width: pxToRem(40);
            height: pxToRem(40);
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: center;

            &.current {
                @include sq-border-radius(999px);
                border: pxToRem(1) solid $ocean;
            }

            a {
                display: flex;
                align-content: center;
                align-items: center;
                justify-content: center;
                width: pxToRem(40);
                height: pxToRem(40);
                color: $greyish-brown-three;

                &:hover,
                &:focus {
                    @include sq-box-shadow(none);
                    text-decoration: underline;
                }
            }
        }
    }


}