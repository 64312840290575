/* Module: S */
.results-list {
  &__badge.member-content {
    position: static;
    float: none;
    margin-left: 0;
  }
  &__menu {
    display: none;
  }
}