/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
//
// .inside-footer__element { ... }
// .inside-footer__element--modifier { ... }
// .inside-footer__sub-element { ... }
.page-feedback {
  margin-bottom: 2rem;
  input[type='radio'] + label {
    margin-right: 1.5rem;
  }
}

.page-feedback__hidden {
  display: none;
  .no-js & {
    display: block;
  }
}

.page-feedback__disclaimer {
  font-size: pxToRem(12);
  line-height: 1.5;
  margin-bottom: 1rem;
}

.page-feedback__submit {
  margin-bottom: 1rem;
  text-align: center;
}

// honeypot question
.page-feedback__hpt {
  display: none;
}

.page-last-updated {
  border-top: 1px solid color(pinkish-grey);
  padding-top: pxToRem(10);
  text-align: left;
  font-size: pxToRem(12);
  line-height: 1.5;
  color: color(black-two);
}
