/* Module: S */
.my-finanace-container {
	
	tr.fltrow{
		display: none;
	}

	.btn-row {
		@include sq-flex-box();
		justify-content: space-between;
		margin-top: 20px;
		margin-bottom: 20px;
		float: right;
	}
}