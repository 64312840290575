/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
//
// .select-state__element { ... }
// .select-state__element--modifier { ... }
// .select-state__sub-element { ... }

.select-state {
  display: none;
  clear: both;
  text-align : center;
  @include mq-tablet() {
    display: block;
    width: 100%;
    height: $select-state-height;
    line-height: $select-state-height;
    background-color: color(ocean);
    .select-state__list {
      @include sq-list-reset();
      li {
        display: inline-block;
        a {
          display: inline-block;
          padding: 0 34px;
          font-family: Cabin;
          font-size: 14px;
          color: white;
          text-decoration: none;
          &:hover,
          &:active,
          &:focus {
            background: color(butterscotch);
            color: color(greyish-brown-three);
            cursor: pointer;
            text-decoration: underline;
          }
        }
        &.active {
          background: color(butterscotch);
          a {
            color: color(greyish-brown-three);
          }
        }
      }
    }
  }
}
