/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
//
// .utils__element { ... }
// .utils__element--modifier { ... }
// .utils__sub-element { ... }

// .utils {
//     // Your styles here
// }

a{
	text-decoration: none;
	&:hover{
		text-decoration: underline;
	}
}
hr {
    border: none;
    border-top: 1px solid color(pinkish-grey);
}
.circle{
	width: 50px;
	height: 50px;
	border-radius: 50%;
	padding: 0;
	text-align: center;
	line-height: 50px;
}

.line {
	display: block;
	width: 140px;
	height: 1px;

	&.center{
		margin: 0 auto;
	}

	&.short{
		width: 75px;
	}

  &.tiny {
    width: 50px;
  }
}

.uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.pad-1em {
  padding: 1em;
}

.header {
  position: relative;
  background-color: color(ocean);
  @include mq-tablet() {
    background-color: transparent;
  }
}

#page-wrapper {
  overflow-x: hidden;
  position: relative;
  &.active-mobile-menu,
  &.active-mobile-member-nav {
    position: fixed;
    max-width: 100%;
    @include mq-tablet() {
      position: relative;
    }
  }
}

.off-canvas {
  background-color: color(white-two);
}

.content-container {
  padding: 2rem;
  .m-top-0 {
    margin-top: 0;
  }
  .m-bottom-0 {
    margin-bottom: 0;
  }
  @include mq-tablet() {
    margin: 0 auto;
    max-width: 986px;
    padding: 3rem;
    &.main {
      border-left: $nav__width--tablet solid transparent;
      max-width: calc(986px + #{$nav__width--tablet});
    }
    &.content-container--full-width {
      border-left: none;
      max-width: 986px;
    }
    .off-canvas__nav + .off-canvas__content & {
      padding-right: 4rem;
    }
  }
}

// Some mobile helpers (mobile version)

.mobile-container,
.container-max-width {
  max-width : 84.375%;
  margin: 0 auto;
}

.container-max-width {
  margin-bottom: 1rem;
  @include mq-tablet() {
    max-width: 890px;
  }
}

.hidden-mobile{
	display: none !important;
}
.hidden-wide{
	display: block !important;
}

.footer{
	background-color: color(greyish-brown);
}

// Clearing

.clear-left {
  clear: left;
}

.clear-right {
  clear: right;
}

// Member content identifiers

.member-content {
  display: inline-block;
  background-color: color(light-navy);
  border-left: 11px solid color(butterscotch);
  color: white;
  padding: 0.875em 1.375em;
  text-transform: uppercase;
  &--small {
    font-size: 13px;
    padding: 0.2em 0.8em;
    border-left-width: 5px;
  }
  a {
    color: white;
    &.active,
    &:hover,
    &:focus,
    &:active {
      @include sq-box-shadow(0 2px white);
    }
  }
}

// Badges
.badge {
  display: inline-block;
  padding: 0.375rem 0.75rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: white;
  line-height: 1;
  height: 1.5rem;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  border-radius: 0.75rem;
  background-color: color(greyish-brown);
  .active & {
    background-color: color(ocean);
  }
}

// print utils
.visible-print {
  display: none !important;

  @media print {
    display: initial !important;
  }
}

// Responsive Video Embed (Content Container Template)
// https://matrix.squiz.net/resources/templates/content-templates
/* Content Template Styles - Video Embed */
.tmp_video-frame-wrapper {
  border-top: 2rem solid transparent;
  box-shadow: 0 -1px color(pinkish-grey);
  height: 0;
  margin: 2rem 0;
  padding-bottom: 56.25%; /* 16:9 */
  position: relative;
}
.tmp_video-wrapper iframe,
.tmp_video-wrapper video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.logo-container {
  margin: 1.5rem auto;
  max-width: 10rem;
}

.embedcode {
  border-top: 1px solid color(pinkish-grey);
  margin: 2rem 0;
  padding-top: 2rem;
  iframe {
    border: 1px solid $matrix-form__border-colour;
    min-height: 320px;
    width: 100%;
  }
}
