/* Module: S */
.footer-social {
  .footer__container {
    display: block;
    padding: 0 2rem;
  }

  .footer-social__icons {
    margin: 0;
    width: 100%;
    a {
      display: block;
      font-size: 1rem;
      margin: 1rem 0;
      text-align: left;
      vertical-align: middle;
      i {
        font-size: 2rem;
        width: 3rem;
        vertical-align: middle;
        text-align: center;
      }
      &:after {
        content: attr(href);
      }
    }
  }

  .footer-social__subscribe {
    display: none;
  }
}
