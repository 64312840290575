/* Module: S */
.search-large {

    &__title {
        margin: 0 0 pxToRem(8) 0;
    }

    .input-wrapper.round-left {
        
        align-items: center;
        flex-flow: row nowrap;

        .fa {
            font-size: pxToRem(17);
            line-height: pxToRem(17);
            display: block;
            color: $black;
            margin: 0 pxToRem(19) 0 pxToRem(10);
        }

    }

    button[type="submit"] {

        width: pxToRem(135);
        min-width: pxToRem(135);

        .fa {
            display: none;
        }

        span {
            @include font-smoothing;
            display: inline-block;
            color: $greyish-brown;
            font-size: pxToRem(14);
            line-height: pxToRem(18);
            text-transform: uppercase;
            font-weight: 500;
        }

        &:hover,
        &:focus {
            span {
                color: $white;
            }
        }
    }

}