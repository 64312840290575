/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
//
// .hero__element { ... }
// .hero__element--modifier { ... }
// .hero__sub-element { ... }

.hero {
  height: 500px;

  .hero__bg {
    &.top-right {
      top: 0;
      right: 0;
      width: 281px;
      height: 330px;
      background-image: url('{{file_dest}}/hero-top-right.png');
      background-position: top left;
    }

  }



  &__content {
    position: absolute;
    top: 5%;
    left: 50%;
    width: 796px;
    transform: translate(-50%, 0);
    font-family: Cabin;
    text-align: center;

    h1 {
      font-size: 48px;
      font-weight: bold;
      text-transform: uppercase;
      color: #000;
      margin-bottom: 28px;

    }

    p {
      font-size: 16px;
      line-height: 1.7;
      text-align: center;
      color: color(greyish-brown-three);
      margin: 28px auto 25px;
    }

    .benefits {
      @include sq-flex-box();
      max-width: 650px;
      .benefits__item {
        font-size: 1rem;
        width: 30%;
        .icon-checkmark {
          &::before {
            vertical-align: -.6rem;
          }
        }
      }
    }

    .action {
      .button {
        font-size: 16px;
      }
    }
  }

  .hero__down-arrow {
    @include sq-transition(bottom 1s);
    position: absolute;
    bottom: 80px;
    left: 50%;
    width: 40px;
    height: 40px;
    margin-left: -20px;
    text-align: center;
    z-index: 2;
    background: #fff;
    border: solid .8px color(white-three);
    border-radius: 50%;
    font-size: 16px;
    line-height: 44px;
    color: color(ocean);

    &.loaded {
      bottom: -20px;
    }
  }




  // regional variation
  &.regional{
      height: 426px;
      .white-gradient{
        width: 80%;
        background-color: transparent;
        @include gradient-horizontal(white, rgba(255,255,255,0), 15%, 100%);
      }
      .hero__content{
        position: absolute;
        top: 15%;
        left: 184px;
        width: 420px;
        text-align: left;
        transform: none;
        h1{
          margin: 0 0 24px;
          padding: 0;
          line-height: 1.1;
          font-size: 48px;
        }
        p{
          text-align: left;
          padding-right: 10px;
        }
      }

  }// end regional

}
