/* Module: S */
.mainnav {
  display: block;
  margin: 0;
  &__logo {
    position: absolute;
    top: 0;
    left: 0;
    &:before {
      content: url('mysource_files/guild_logo_print.png');
    }
    a {
      img {
        display: none;
      }
      &:after{
        display: none;
      }
    }
  }
  &__list,
  &__right {
    display: none !important;
  }
}

.search {
  display: none !important;
}