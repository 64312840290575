/* Module: S */
.modal__bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.modal__wrap {
  top: 50%;
  left: 50%;
  width: 90%;
  min-height: 50%;
  z-index: 1043;
  position: fixed;
  transform: translate(-50%, -50%);

  .modal__content {
    position: relative;
    background-color: white;
    color: color(greyish-brown-three);
    overflow-y: auto;
    width: 100%;
    height: 100%;
    padding: 2.5rem;
    text-align: center;
  }

  .modal__close {
    position: absolute;
    cursor: pointer;
    top: 1rem;
    right: 1rem;
    text-decoration: none;
    text-align: center;
    color: color(greyish-brown-three);
    @include sq-transition(transform .2s);
    &:hover,
    &:focus,
    &:active {
      @include sq-box-shadow(none);
      @include sq-transform(scale(1.25));
    }
  }
  .sq-form {
    text-align: left;
    margin: 10px auto;
    label {
      display: block;
      text-align: left;
    }
    .input-control{
      width: 100%;
      max-width: none;
    }
  }
  .btn-row{
    @include sq-flex-box();
    justify-content: space-around;
    flex-direction: column-reverse;

    .button {
      margin-top: 5px;
      & + .button {
        margin-left: 0;
      }
    }
  }
}
