/* Module: S */
@page {
  size: A4;
  margin: 14mm 21mm;
}

.hide-print {
  display: none !important;
}

#page-wrapper {
  position: static;
  &.active-mobile-menu,
  &.active-mobile-member-nav, {
    position: static;
  }
}

.off-canvas__content {
  width: 100% !important;
}

.content-container {
  position: static !important;
}

.main,
.main.content-container {
  position: static !important;
  padding: 3rem 0 2rem !important;
}

// Chrome 29+ only
@media print and (-webkit-min-device-pixel-ratio: 0) and (min-resolution:.001dpcm) {
  .main,
  .main.content-container {
    position: static !important;
    padding: 5.75rem 0 2rem !important;
  }
}

.mobile-container,
.container-centered {
  max-width: 100%;
}

th, td {
  page-break-inside: avoid;
}

figcaption {
  page-break-before: avoid;
}

caption {
  page-break-after: avoid;
}

.member-content {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 0;
  &:before {
    content: url('mysource_files/member-content.png');
  }
}
