/* Module: S */
.header {
  background-color: white;
}

.main {
  min-height: 1390px; // magic number for member nav height
}

.content-container {
  margin: 0 auto;
  max-width: 1050px;
  padding: 5rem;
  &.main {
    border-left: $nav__width solid transparent;
    max-width: calc(1050px + #{$nav__width});
  }
  &.content-container--full-width {
    border-left: none;
    max-width: 1050px;
  }
}

.mobile-container{
  max-width: 100%;
}

.hidden-mobile{
	display: block !important;
}
.hidden-wide{
	display: none !important;
}
#page-wrapper {
  &.active-mobile-menu,
  &.active-mobile-member-nav {
    position: relative;
  }
}
