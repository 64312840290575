/* Module: S */
.list-tiles {

    @media screen and (min-width: 1000px){
        display: flex;
        justify-content: space-between;
    }

    &__item {

        padding: pxToRem(45) pxToRem(20) pxToRem(36) pxToRem(20);
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        background-color: $white;
        align-items: flex-start;
        align-items: flex-start;
        width: 100%;

        @media screen and (min-width: 1000px){
            width: 50%;
        }

        &:nth-child(odd){
            background-color: $very-light-pink-five;
        }

        &-tag {
            @include font-smoothing;
            color: $white;
            font-size: pxToRem(13);
            line-height: pxToRem(14);
            font-weight: 400;
            background-color: $ocean;
            border-left: pxToRem(5) solid $butterscotch;
            width: auto;
            text-transform: uppercase;
            padding: pxToRem(7) pxToRem(10);
        }

        &-title {
            @include font-smoothing;
            font-size: pxToRem(18);
            line-height: pxToRem(21);
            color: $black;
            font-weight: 600;
            width: 100%;
            display: flex;
            justify-content: center;
            flex-flow: row wrap;
            margin: pxToRem(25) 0 0 0;
            position: relative;

            span {
                width: 100%;
                display: block;
                text-align: center;
                padding-bottom: pxToRem(14);
            }

            &:after {
                content: "";
                display: block;
                width: pxToRem(65);
                height: pxToRem(1);
                background-color: $ocean;
            }
        }

        &-content {
            text-align: center;
            font-size: pxToRem(16);
            line-height: pxToRem(23);
            color: $greyish-brown-four;
            margin: pxToRem(17) 0 0 0;
            max-width: pxToRem(560);
            width: 100%;
        }

        &-cta {
            margin: pxToRem(30) 0 0 0;
            width: 100%;
            text-align: center;
        }

    }


}