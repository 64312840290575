/* Module: Matrix form */
.sq-form-section,
.sq-form {
  input {
    &[type='radio'],
    &[type='checkbox'] {
      position: inherit;
      z-index: auto;
      opacity: 1;
    }
    &[type='radio'] + label,
    &[type='checkbox'] + label {
      display: inline-block;
      position: inherit;
      margin: initial;
      padding-left: initial;
      font-weight: normal;
      &:before,
      &:after {
        content: '';
        display: none;
      }
    }
  }
}
