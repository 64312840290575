/* Module: S */
// Use on figure
.image-caption {
  width: inherit;
  text-align: inherit;
  &--left {
    float: left;
    margin-right: 3.5rem;
  }
  &--right {
    float: right;
    margin-left: 3.5rem;
  }
}
