/* Module: Responsive tables */
.responsive-table {
  margin: 1.5rem 0;
  caption {
    caption-side: top;
    font-size: 1rem;
    font-style: normal;
    color: inherit;
    margin: 0.5rem 0 1rem;
  }
  thead {
    position: absolute;
    top: 0;
    left: -999em;
    border: none;
    font-weight: bold;
    color: black;
  }
  tbody,
  tr,
  th,
  td {
    display: block;
    white-space: normal;
  }
  tr {
    border: 1px solid color(pinkish-grey);
    margin-bottom: 1.5rem;
  }
  th {
    padding: 1rem;
    &[scope="row"] {
      background-color: color(white-three);
      color: black;
    }
  }
  td,
  th[scope="row"] {
    border: none;
    border-bottom: 1px solid color(pinkish-grey);
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    &:before {
      content: attr(data-th);
      display: inline-block;
      float: left;
      font-weight: bold;
      padding-right: 0.5rem;
    }
    &:empty:after {
      content: " ";
      display: block;
      height: 1rem;
    }
    &:last-child {
      border-bottom: none;
    }
  }
}

.table-alt,
table:not([class]) {
  th {
    background-color: #143980;
  }
  th,
  td {
    border: none;
    border-right: 1px solid color(white-two);
    padding: 5px 10px;
  }
  tbody tr:nth-child(2n) {
    background-color: white;
  }
  tbody tr:last-child td {
    border-bottom: 1px solid white;
  }
}
