/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of 
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
// 
// .footer-nav__element { ... }
// .footer-nav__element--modifier { ... }
// .footer-nav__sub-element { ... }

.footer-nav {
  h4 {
    text-transform: uppercase;
  }

  .row {
    display: block;
    padding-top: 40px;
    padding-bottom: 30px;
    width: 266px;
    margin: 0 auto;

    .col {
      width: 32%;
      display: inline-block;

      h4 {
        margin-bottom: 14px;

        a {
          font-size: 12px;
          line-height: 20px;
        }
      }

      a {
        color: #fff;
      }

      ul {
        display: none;
        padding-left: 0;
        list-style: none;

        li {
          height: 30px;
          line-height: 30px;

          &.separator {
            height: 15px;
            line-height: 15px;

            .line {
              margin-top: 14px;
              width: 30px;
            }
          }
        }
      }
    }
  }

  .copyright {
    width: 266px;
    margin: 0 auto;
    font-size: 10px;
    line-height: 1.6;
    padding-bottom: 54px;

    span {
      display: block;
    }
  }
}
