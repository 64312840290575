/* Module: S */
.social-blocks {

    &__item {
        width: 33.33333333%;
        padding: pxToRem(95) pxToRem(40) pxToRem(20) pxToRem(40);
        min-height: pxToRem(480);
        position: relative;

        &--youtube {
            min-height: pxToRem(480);

            &:before {
                height: 60%;
                opacity: 0.95;
            }

            .social-blocks__item-title {
                max-width: pxToRem(300);
            }

            .social-blocks__item-footer {
                position: relative;
                padding: 0;
            }
        }

        &-title {
            max-width: pxToRem(345);
            width: 100%;
            margin: 0 0 pxToRem(20) 0;
            
        }

        &-content {
            font-size: pxToRem(20);
            line-height: pxToRem(24);
            width: 100%;
            max-width: pxToRem(345);
            margin: 0;
        }

        &-footer {
            padding: pxToRem(65) pxToRem(40) pxToRem(20) pxToRem(40);
        }
    }

}