/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
//
// .partners__element { ... }
// .partners__element--modifier { ... }
// .partners__sub-element { ... }

.partners {
  padding-top: 100px;
  padding-bottom: 38px;

  .row {
    @include sq-flex-box();
    width: 100%;;
    margin: 0 auto;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .logo {
    @include sq-flex( 1 1 20% );
    height: 60px;
    margin-bottom: 50px;

    &:hover {
      text-decoration: none;
    }
  }
}

// speical width for offcanvas
.off-canvas__content {
  .partners {
    .row {
      width: 95%;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
    }

    .logo {
      max-width: 160px;
    }
  }
}
