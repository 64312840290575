/* Module: S */
.social-blocks {

    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: stretch;
    align-items: stretch;

    &__item {
        padding: pxToRem(40) pxToRem(20) pxToRem(20) pxToRem(20);
        color: $white;
        width: 100%;
        position: relative;

        .fa {
            font-size: pxToRem(17);
            line-height: pxToRem(17);
        }

        &--fb,
        &--twitter {
            .social-blocks__platform-icon {
                a {
                    border: 1px solid $white;
        
                    &:hover,
                    &:focus {
                        background-color: $white;
                    }
                    
                    .fa {
                        @include sq-transition(color 0.4s ease);
                        color: $white;
                    }
                }
            }
        }

        &--fb {
            background-color: $dark-slate-blue;

            .social-blocks__platform-icon {
                a {
        
                    &:hover,
                    &:focus {
        
                        .fa {
                            color: $dark-slate-blue;
                        }
                    }
                }
            }
        }

        &--twitter {
            background-color: $nice-blue;

            .social-blocks__platform-icon {
                a {
        
                    &:hover,
                    &:focus {
        
                        .fa {
                            color: $nice-blue;
                        }
                    }
                }
            }
        }

        &--youtube {
            
            color: $black;
            background-repeat: no-repeat;
            background-position: top center;
            background-size: cover;
            overflow: hidden;
            display: flex;
            align-items: stretch;
            flex-flow: row wrap;
            align-content: stretch;
            min-height: pxToRem(320);
            display: flex;
            align-items: flex-end;
            flex-flow: row wrap;
            align-content: flex-end;
            width: 100%;

            &:before {
                content: "";
                background: $white;
                background: linear-gradient(0deg, rgba($white,1) 35%, rgba($white,0) 100%);
                height: 80%;
                width: 100%;
                position: absolute;
                left: 0;
                bottom: 0;
                opacity: 0.95;
            }

            .social-blocks__item-title {
                font-size: pxToRem(20);
                line-height: pxToRem(24);
                font-weight: 600;
                width: 100%;
                max-width: pxToRem(230);
                margin: 0 0 pxToRem(17) 0;
                color: $black;

                a {
                    color: $black;

                    &:hover,
                    &:focus {
                        text-decoration: underline;
                    }
                }
            }

            .social-blocks__user {
                color: $black;
                
                a {
                    color: $black;

                    &:hover,
                    &:focus {
                        text-decoration: underline;
                    }
                }
            }

            .social-blocks__platform-icon {

                .fa {
                    font-size: pxToRem(31);
                    line-height: pxToRem(31);
                }
            }
        }

        &--bg-yellow {
            background-color: $butterscotch;
            color: $black;

            .social-blocks__platform-icon {
                a {
                    border: 1px solid $black;
        
                    &:hover,
                    &:focus {
                        background-color: $black;
        
                        .fa {
                            color: $white;
                        }
                    }
                    
                    .fa {
                        @include sq-transition(color 0.4s ease);
                        color: $black;
                    }
                }
            }
        }

        &--bg-teal {
            background-color: $ocean;

            .social-blocks__platform-icon {
                a {
                    border: 1px solid $white;
        
                    &:hover,
                    &:focus {
                        background-color: $white;
        
                        .fa {
                            color: $ocean;
                        }
                    }
                    
                    .fa {
                        @include sq-transition(color 0.4s ease);
                        color: $white;
                    }
                }
            }
        }

        &-title {
            @include font-smoothing;
            font-size: pxToRem(18);
            line-height: pxToRem(23);
            font-weight: 400;
            margin: 0 0 pxToRem(30) 0;
            position: relative;
            z-index: 5;
        }

        &-content {
            @include font-smoothing;
            font-size: pxToRem(16);
            line-height: pxToRem(24);
            font-weight: 600;
            margin: 0 0 pxToRem(45) 0;
        }

        &-footer {
            position: relative;
            z-index: 5;
            display: flex;
            justify-content: space-between;
            align-content: center;
            align-items: center;
            width: 100%;
        }

    }

    &__user {
        font-size: pxToRem(18);
        line-height: pxToRem(23);
    }

    &__platform-icon {
        @include sq-border-radius(999px);
        width: pxToRem(60);
        height: pxToRem(60);

        a {
            @include sq-border-radius(999px);
            @include sq-transition(background-color 0.4s ease);
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            width: pxToRem(60);
            height: pxToRem(60);
            border: 1px solid $black;

            &:hover,
            &:focus {
                background-color: $black;
                text-decoration: none;

                .fa {
                    color: $white;
                }
            }
            
            .fa {
                @include sq-transition(color 0.4s ease);
                color: $black;
            }
        }
    }


}