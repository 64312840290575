/* Module: S */
// Slide down buttons

%button-base {
  border: none;
  color: color(greyish-brown-three);
  cursor: pointer;
  display: inline-block;
  font-family: Cabin;
  font-weight: 500;
  outline: none;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  text-align: center;
  @include sq-font-size(0.875);
}

.content-container .button,
.button {
  background: color(butterscotch);
  min-width: 0;
  padding: 1rem 1.625rem;
  @extend %button-base;
  @include sq-box-shadow(inset 0 0 0 0 transparent);
  @include sq-transition(all 0.2s);

  &:not(:disabled).active,
  &:not(:disabled):hover,
  &:not(:disabled):active,
  &:not(:disabled):focus {
    color: white;
    cursor: pointer;
    text-decoration: none;
    @include sq-box-shadow(inset 0 $button-max-height 0 0 color(ocean));
  }

  &:disabled {
    opacity: 0.5;
    &.active,
    &:hover,
    &:active,
    &:focus {
      cursor: not-allowed;
    }
  }

  &.button--small {
    min-width: 0;
    padding: 0.5rem 1rem;
    font-size: pxToRem(13);
  }

  &.button--alt-hover {
    &:not(:disabled).active,
    &:not(:disabled):hover,
    &:not(:disabled):active,
    &:not(:disabled):focus {
      color: color(greyish-brown-three);
      text-decoration: none;
      @include sq-box-shadow(inset 0 $button-max-height 0 0 white);
    }
  }
  
  &.button--outline {
    background: none;
    border: 1px solid color(pinkish-grey);
    &:not(:disabled).active,
    &:not(:disabled):hover,
    &:not(:disabled):active,
    &:not(:disabled):focus {
      border-color: color(ocean);
      color: white;
    }
  }

  &.button--dark {
    background: color('light-navy');
    color: color('white');
  }

  &.circle {
    min-width: 0;
  }
}

.content-container .button + .button {
  margin-left: 1.5rem;
}


.content-container .button--link{
  background: none;
  border: none;
  &:hover {
    text-decoration: underline;
  }
}