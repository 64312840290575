/* Module: S */
.link-block {
  margin-bottom: pxToRem(15);

  .link-block__title,
  .link-block__description {
    padding: pxToRem(10);
    display: block;
    color: $black;
  }

  a {
    &.link-block__title,
    &.link-block__description {
      &:hover,
      &:focus {
        box-shadow: none;
        text-decoration: underline;
      }
    }
  }

  .link-block__title {
    border: pxToRem(1) solid color(butterscotch);
    background-color: color(butterscotch);
    font-size: pxToRem(18);
    font-weight: bold;
    border-top-left-radius: pxToRem(10);
    border-top-right-radius: pxToRem(10);
    text-transform: uppercase;
  }

  .link-block__description {
    border: pxToRem(2) solid color(butterscotch);
    background-color: $white;
    border-bottom-left-radius: pxToRem(10);
    border-bottom-right-radius: pxToRem(10);
  }
}

.links-block {
  &.theme--navy {
    .link-block__title {
      border: pxToRem(1) solid color(guild-navy);
      background-color: color(guild-navy);
      color: $white;
    }

    .link-block__description {
      border: pxToRem(2) solid color(guild-navy);
    }
  }

  &.theme--teal {
    .link-block__title {
      border: pxToRem(1) solid color(guild-teal);
      background-color: color(guild-teal);
      color: $white;
    }

    .link-block__description {
      border: pxToRem(2) solid color(guild-teal);
    }
  }
}