/* Tables */

table {
    width: 100%;
    border-collapse: collapse;
    margin: .5em 0;
}

caption {
    @include sq-font-size(.8);
    font-style: italic;
}

th {
    background: $base-font-colour;
    color: invert($base-font-colour);
}

td {
    border: 1px solid $base-font-colour;
}


/* Quotes */

blockquote {
    position: relative;
    padding-left: 3em;
    margin: 2em 0;
    &:before {
        @include sq-font-size(5);
        color: $base-font-colour;
        content: "\201C";
        position: absolute;
        top: 0;
        left: 0;
        line-height: 1em;
    }
}

.pull-quote {
    border-top: 1px solid $base-font-colour;
    padding: 1em .5em 0 .5em;
    margin: 1em;
}


/* Highlight */

.highlight {
    background: #cccccc;
    @extend %inline-block;
    padding: .1em .5em;
}


/* article block format */

.container__article {
    overflow: auto;
}

.article-image__left {
    display: block;
    float: left;
    margin-top: 5px;
    margin-right: 20px;
    margin-bottom: 16px;
}

.article-image__right {
    display: block;
    float: right;
    margin-top: 5px;
    margin-left: 20px;
    margin-bottom: 16px;
}

.article-text {
    word-wrap: break-word;
}
